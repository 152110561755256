var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "row align-items-end" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v(_vm._s(_vm.field.label)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6 d-sm-flex justify-content-end mb-2" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  class: { "toggle-active": _vm.showPreview },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.showPreview = !_vm.showPreview
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-eye" }),
                  _vm._v(" " + _vm._s(_vm.$t("Link preview")) + "\n        "),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body p-2" },
            _vm._l(_vm.links, function (link, index) {
              return _c("div", { key: link.id }, [
                _vm.showPreview
                  ? _c("div", { staticClass: "card bg-light mb-1" }, [
                      _c("div", { staticClass: "card-body py-1 px-2" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("small", [
                              _vm.currentRecordIdentifier
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        title: _vm.currentRecordIdentifier,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      @" +
                                          _vm._s(
                                            _vm.kadi.utils.truncate(
                                              _vm.currentRecordIdentifier,
                                              25
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _c("em", [
                                    _vm._v(_vm._s(_vm.$t("New record"))),
                                  ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-4 d-md-flex justify-content-center",
                            },
                            [
                              _c("span", [
                                link.direction === "in"
                                  ? _c("i", {
                                      staticClass:
                                        "fa-solid fa-arrow-left-long fa-lg mr-2",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.kadi.utils.truncate(
                                        link.name || "",
                                        20
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                link.direction === "out"
                                  ? _c("i", {
                                      staticClass:
                                        "fa-solid fa-arrow-right-long fa-lg ml-2",
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-4 d-md-flex justify-content-end",
                            },
                            [
                              _c("small", [
                                link.record
                                  ? _c(
                                      "span",
                                      { attrs: { title: link.record[1] } },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.kadi.utils.truncate(
                                                link.record[1],
                                                25
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : _c("em", [
                                      _vm._v(_vm._s(_vm.$t("Linked record"))),
                                    ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-row",
                    class: {
                      "mb-3": index < _vm.links.length - 1 && !link.editTerm,
                    },
                  },
                  [
                    _c("div", { staticClass: "col-md-3 mb-1 mb-md-0" }, [
                      _c("div", { staticClass: "input-group input-group-sm" }, [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v(_vm._s(_vm.$t("Direction"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: link.direction,
                                expression: "link.direction",
                              },
                            ],
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  link,
                                  "direction",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.directions, function (direction) {
                            return _c(
                              "option",
                              {
                                key: direction[0],
                                domProps: { value: direction[0] },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(direction[1]) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-4 mb-1 mb-md-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "input-group input-group-sm" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _vm._v(_vm._s(_vm.$t("Record"))),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("dynamic-selection", {
                              class: {
                                "has-error":
                                  _vm.getErrors(index, "record").length > 0,
                              },
                              attrs: {
                                "container-classes": "select2-single-sm",
                                endpoint: _vm.recordsEndpoint,
                                "initial-values": _vm.getInitialRecord(link),
                                placeholder: _vm.$t("Search for records"),
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.selectRecord(link, $event)
                                },
                                unselect: function ($event) {
                                  link.record = null
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.getErrors(index, "record"),
                          function (error) {
                            return _c(
                              "div",
                              { key: error, staticClass: "invalid-feedback" },
                              [_vm._v(_vm._s(error))]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3 mb-1 mb-md-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "input-group input-group-sm" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _vm._v(_vm._s(_vm.$t("Name"))),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("dynamic-selection", {
                              class: {
                                "has-error":
                                  _vm.getErrors(index, "name").length > 0,
                              },
                              attrs: {
                                "container-classes": "select2-single-sm",
                                endpoint: _vm.namesEndpoint,
                                "initial-values": _vm.getInitialName(link),
                                placeholder: _vm.$t(
                                  "Enter or search for a name"
                                ),
                                "max-input-length":
                                  _vm.field.validation.max.name,
                                tags: true,
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.selectName(link, $event)
                                },
                                unselect: function ($event) {
                                  link.name = null
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "input-group-text btn btn-light",
                                  class: { "toggle-active": link.editTerm },
                                  attrs: {
                                    type: "button",
                                    title: _vm.$t("Toggle term IRI"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      link.editTerm = !link.editTerm
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa-solid fa-link" })]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.getErrors(index, "name"), function (error) {
                          return _c(
                            "div",
                            { key: error, staticClass: "invalid-feedback" },
                            [_vm._v(_vm._s(error))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "div",
                        { staticClass: "btn-group btn-group-sm w-100" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: {
                                type: "button",
                                title: _vm.$t("Add link"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addLink(null, index)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa-solid fa-plus" })]
                          ),
                          _vm._v(" "),
                          _vm.links.length > 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-light",
                                  attrs: {
                                    type: "button",
                                    title: _vm.$t("Remove link"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeLink(index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa-solid fa-xmark" })]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: link.editTerm,
                        expression: "link.editTerm",
                      },
                    ],
                    staticClass: "mt-1",
                    class: { "mb-3": index < _vm.links.length - 1 },
                  },
                  [
                    _c("div", { staticClass: "input-group input-group-sm" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(_vm._s(_vm.$t("Term IRI"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: link.term,
                            expression: "link.term",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "has-error": _vm.getErrors(index, "term").length > 0,
                        },
                        domProps: { value: link.term },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(link, "term", $event.target.value.trim())
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.termsEndpoint
                        ? _c("div", { staticClass: "input-group-append" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showTermSearch(link)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa-solid fa-search" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Find term")) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.getErrors(index, "term"), function (error) {
                      return _c(
                        "div",
                        { key: error, staticClass: "invalid-feedback" },
                        [_vm._v(_vm._s(error))]
                      )
                    }),
                    _vm._v(" "),
                    _vm.getErrors(index, "term").length === 0
                      ? _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "An IRI specifying an existing term that the link should represent."
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "form-text text-muted" }, [
          _vm._v(_vm._s(_vm.field.description)),
        ]),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: _vm.field.name },
          domProps: { value: _vm.serializedLinks },
        }),
      ]),
      _vm._v(" "),
      _vm.termsEndpoint
        ? _c("term-search", {
            ref: "termSearch",
            attrs: { endpoint: _vm.termsEndpoint },
            on: { "select-term": _vm.selectTerm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }