import { render, staticRenderFns } from "./TextViewer.vue?vue&type=template&id=61bc9eab&scoped=true"
import script from "./TextViewer.vue?vue&type=script&lang=js"
export * from "./TextViewer.vue?vue&type=script&lang=js"
import style0 from "./TextViewer.vue?vue&type=style&index=0&id=61bc9eab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bc9eab",
  null
  
)

export default component.exports