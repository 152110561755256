var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "form",
      attrs: { action: _vm.action, method: _vm.method, enctype: _vm.enctype },
      on: { change: _vm.onChange, submit: _vm.onSubmit },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }