var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { directives: [{ name: "trim-ws", rawName: "v-trim-ws" }] },
    [
      _c(
        "a",
        {
          ref: "toggle",
          staticClass: "cursor-pointer",
          class: _vm.toggleClass,
          attrs: { tabindex: "-1", "data-toggle": "popover" },
        },
        [_vm._t("toggle")],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { ref: "popoverContent", staticClass: "d-none" },
        [_vm._t("content")],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { ref: "popoverTitle", staticClass: "d-none" },
        [
          _vm._t("title", function () {
            return [
              _vm.title ? _c("strong", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }