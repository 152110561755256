var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { required: _vm.field.validation.required },
    },
    [
      _c(
        "label",
        { staticClass: "form-control-label", attrs: { for: _vm.field.id } },
        [_vm._v(_vm._s(_vm.field.label))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _vm.fileSelected
          ? _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "button",
                {
                  staticClass: "btn clear-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.clearFiles },
                },
                [_c("i", { staticClass: "fa-solid fa-xmark" })]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "custom-file" }, [
          _c("input", {
            ref: "input",
            staticClass: "custom-file-input",
            attrs: {
              id: _vm.field.id,
              type: "file",
              name: _vm.field.name,
              required: _vm.field.validation.required,
              accept: _vm.mimetypes.join(","),
              disabled: _vm.disabled,
            },
            on: { change: _vm.changeFile },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "custom-file-label",
              class: {
                "has-error": _vm.field.errors.length > 0 || _vm.errorMessage,
              },
              attrs: { "data-i18n": _vm.$t("Choose file") },
            },
            [_vm._v("\n        " + _vm._s(_vm.message) + "\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.field.errors, function (error) {
        return _c("div", { key: error, staticClass: "invalid-feedback" }, [
          _vm._v(_vm._s(error)),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "invalid-feedback" }, [
        _vm._v(_vm._s(_vm.errorMessage)),
      ]),
      _vm._v(" "),
      _vm.field.errors.length === 0 && !_vm.errorMessage
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }