var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "card bg-light max-vh-75 overflow-auto": _vm.depth === 0 } },
    [
      _c("div", { class: { "mt-3 mr-4": _vm.depth === 0 } }, [
        _c(
          "ul",
          { style: _vm.listWidth },
          _vm._l(_vm.entries_, function (entry) {
            return _c("li", { key: entry.id }, [
              _c(
                "div",
                { staticClass: "entry d-flex justify-content-between px-1" },
                [
                  _c(
                    "div",
                    [
                      !entry.is_group
                        ? _c("div", { staticClass: "text-break" }, [
                            _c("i", { staticClass: "fa-solid fa-file" }),
                            _vm._v(" " + _vm._s(entry.name) + "\n            "),
                          ])
                        : _c(
                            "collapse-item",
                            {
                              attrs: {
                                id: entry.id,
                                "show-icon-class": "fa-solid fa-folder",
                                "hide-icon-class": "fa-solid fa-folder-open",
                              },
                            },
                            [
                              _c("strong", { staticClass: "text-break" }, [
                                _vm._v(_vm._s(entry.name)),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasDetails(entry)
                    ? _c(
                        "collapse-item",
                        {
                          attrs: {
                            id: `${entry.id}-details`,
                            "is-collapsed": true,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Details")) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { attrs: { id: `${entry.id}-details` } }, [
                entry.meta
                  ? _c(
                      "div",
                      { staticClass: "card card-body py-2 my-2" },
                      [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("Object info")))]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "my-1" }),
                        _vm._v(" "),
                        _vm._l(_vm.metaAttrs, function (attr) {
                          return _c(
                            "div",
                            { key: attr[1], staticClass: "row mb-2 mb-md-0" },
                            [
                              _c("div", { staticClass: "col-md-4" }, [
                                _vm._v(_vm._s(attr[1])),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-8" }, [
                                _vm._v(_vm._s(entry.meta[attr[0]])),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasAttrs(entry)
                  ? _c(
                      "div",
                      { staticClass: "card card-body py-2 my-2" },
                      [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("Attributes")))]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "my-1" }),
                        _vm._v(" "),
                        _vm._l(entry.attrs, function (value, prop) {
                          return _c(
                            "div",
                            { key: prop, staticClass: "row mb-2 mb-md-0" },
                            [
                              _c("div", { staticClass: "col-md-4" }, [
                                _vm._v(_vm._s(prop)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-8" }, [
                                _vm._v(_vm._s(value)),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              entry.is_group
                ? _c(
                    "div",
                    [
                      _c("hdf-viewer", {
                        attrs: {
                          id: entry.id,
                          entries: entry.children,
                          depth: _vm.depth + 1,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }