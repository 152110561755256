var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "mx-2" }, [
      _c("div", { staticClass: "form-row align-items-center my-2" }, [
        _c("div", { staticClass: "col-md-2 text-muted" }, [
          _c("small", [_vm._v(_vm._s(_vm.$t("Required")))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-10" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.required,
                expression: "required",
              },
            ],
            staticClass: "align-middle",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.required)
                ? _vm._i(_vm.required, null) > -1
                : _vm.required,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.required,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.required = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.required = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.required = $$c
                }
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.isNumericType
        ? _c("div", { staticClass: "form-row align-items-center my-2" }, [
            _c("div", { staticClass: "col-md-2 text-muted" }, [
              _c("small", [_vm._v(_vm._s(_vm.$t("Range")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-10" }, [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("div", { staticClass: "input-group input-group-sm mr-1" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    domProps: {
                      value: _vm.kadi.utils.toExponentional(_vm.range.min),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeRange("min", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group input-group-sm ml-1" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    domProps: {
                      value: _vm.kadi.utils.toExponentional(_vm.range.max),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeRange("max", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      ["str", "int", "float"].includes(_vm.type)
        ? _c("div", { staticClass: "form-row align-items-center my-2" }, [
            _c("div", { staticClass: "col-md-2 text-muted" }, [
              _c("small", [_vm._v(_vm._s(_vm.$t("Options")))]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-10" },
              [
                _c(
                  "vue-draggable",
                  {
                    attrs: {
                      handle: ".sort-handle",
                      list: _vm.options,
                      "force-fallback": true,
                    },
                    on: { end: _vm.endDrag },
                  },
                  _vm._l(_vm.options, function (option, index) {
                    return _c(
                      "div",
                      {
                        key: option.id,
                        staticClass: "form-row",
                        class: {
                          "mb-md-1 mb-3": index < _vm.options.length - 1,
                        },
                      },
                      [
                        _c("div", { staticClass: "col-md-10 mb-1 mb-md-0" }, [
                          _c("input", {
                            staticClass: "form-control form-control-sm",
                            domProps: { value: _vm.getOptionValue(option) },
                            on: {
                              change: function ($event) {
                                return _vm.changeOption(
                                  option,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c(
                            "div",
                            { staticClass: "btn-group btn-group-sm w-100" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-light",
                                  attrs: { type: "button", tabindex: "-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addOption(null, index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa-solid fa-plus" })]
                              ),
                              _vm._v(" "),
                              _vm.options.length > 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-light",
                                      attrs: { type: "button", tabindex: "-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeOption(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa-solid fa-xmark",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "btn btn-light disabled sort-handle",
                                  attrs: { tabindex: "-1" },
                                },
                                [_c("i", { staticClass: "fa-solid fa-bars" })]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("small", { staticClass: "form-text text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("Possible values of this metadatum."))),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "str"
        ? _c("div", { staticClass: "form-row align-items-center my-2" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.iri,
                    expression: "iri",
                  },
                ],
                staticClass: "align-middle",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.iri)
                    ? _vm._i(_vm.iri, null) > -1
                    : _vm.iri,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.iri,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.iri = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.iri = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.iri = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Whether the value of this metadatum represents an IRI."
                    )
                  )
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("≥")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("≤")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2 text-muted" }, [
      _c("small", [_vm._v("IRI")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }