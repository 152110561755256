var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-dialog", {
    ref: "dialog",
    attrs: { title: _vm.$t("Find term") },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "input-group input-group-sm mb-3" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.query,
                      expression: "query",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "query-input",
                    placeholder: _vm.$t("Search for terms"),
                  },
                  domProps: { value: _vm.query },
                  on: {
                    change: function ($event) {
                      $event.stopPropagation()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.query = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("clear-button", {
                  attrs: { "input-id": "query-input", input: _vm.query },
                  on: {
                    "clear-input": function ($event) {
                      _vm.query = ""
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.initialized
              ? _c("dynamic-pagination", {
                  attrs: {
                    endpoint: _vm.endpoint,
                    placeholder: _vm.$t("No terms."),
                    "per-page": 5,
                    args: { query: _vm.query },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            props.total > 0
                              ? _c("div", [
                                  _c(
                                    "ul",
                                    { staticClass: "list-group" },
                                    _vm._l(props.items, function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.id,
                                          staticClass: "list-group-item py-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-lg-10 mb-2 mb-lg-0",
                                                },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.body
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-lg-2 d-lg-flex justify-content-end",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-light",
                                                      attrs: {
                                                        type: "button",
                                                        "data-dismiss": "modal",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectTerm(
                                                            item.term
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Select term"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-muted" }, [
                                    _c("i", {
                                      staticClass: "fa-solid fa-circle-info",
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "Note that these results are provided by an external terminology service."
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3074221317
                  ),
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }