var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-header" }, [
      _c(
        "ul",
        { staticClass: "nav nav-tabs nav-fill card-header-tabs" },
        _vm._l(_vm.tabs, function (tab) {
          return _c("li", { key: tab, staticClass: "nav-item" }, [
            _c(
              "span",
              {
                ref: `${tab}-trigger`,
                refInFor: true,
                staticClass: "nav-link px-2",
                attrs: { "data-target": `#${tab}-tab` },
                on: {
                  click: function ($event) {
                    return _vm.changeTab(tab)
                  },
                },
              },
              [_vm._t(`${tab}-head`)],
              2
            ),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "tab-content" },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "div",
            { key: tab, staticClass: "tab-pane", attrs: { id: `${tab}-tab` } },
            [
              _vm.loadedTabs.includes(tab)
                ? _c("div", [_vm._t(`${tab}-body`)], 2)
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }