var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [{ name: "trim-ws", rawName: "v-trim-ws" }],
        staticClass: "d-flow-root",
      },
      [
        _c(
          "small",
          [
            _c("resource-visibility", {
              attrs: { visibility: _vm.resource.visibility },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("strong", { staticClass: "text-break ml-2" }, [
          _vm._v(_vm._s(_vm.resource.title)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("small", { staticClass: "text-break" }, [
      _vm._v("@" + _vm._s(_vm.resource.identifier)),
    ]),
    _vm._v(" "),
    _vm.showDescription
      ? _c("div", [
          _vm.resource.plain_description
            ? _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.kadi.utils.truncate(
                        _vm.resource.plain_description,
                        100
                      )
                    ) +
                    "\n    "
                ),
              ])
            : _c("small", { staticClass: "text-muted" }, [
                _c("em", [_vm._v(_vm._s(_vm.$t("No description.")))]),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }