var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.queries, function (query, index) {
      return _c("div", { key: query.id }, [
        _c("div", { staticClass: "form-row mb-4 mb-xl-2" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xl-1 mb-1 mb-xl-0 d-flex justify-content-center",
            },
            [
              index === 0
                ? _c("popover-toggle", {
                    staticClass: "w-100",
                    attrs: {
                      "toggle-class":
                        "btn btn-sm btn-block btn-light text-muted",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "toggle",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass:
                                  "help-icon fa-regular fa-circle-question",
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("Help")) + "\n          "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      'This menu allows searching the generic extra metadata of records, including keys, types and different kinds of values based on the selected types. Multiple such queries can be combined with an "AND" or an "OR" operation in the form of "(Q1 AND Q2) OR (Q3 AND Q4)".'
                                    )
                                  ) +
                                  "\n            "
                              ),
                              _c("hr", { staticClass: "my-1" }),
                              _vm._v(" "),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      'Note that keys inside of nested metadata entries are indexed in the form of "key_1.key_2". In case of list entries, keys are replaced by the corresponding index in the list instead, starting at 1.'
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              index > 0
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: query.link,
                          expression: "query.link",
                        },
                      ],
                      staticClass: "custom-select custom-select-sm",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            query,
                            "link",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.links, function (link) {
                      return _c(
                        "option",
                        { key: link[0], domProps: { value: link[0] } },
                        [_vm._v(_vm._s(link[1]))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-2 mb-1 mb-xl-0" }, [
            _c("div", { staticClass: "input-group input-group-sm" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c("span", { staticClass: "input-group-text" }, [
                  _vm._v(_vm._s(_vm.$t("Type"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: query.type,
                      expression: "query.type",
                    },
                  ],
                  staticClass: "custom-select custom-select-sm",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        query,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }),
                  _vm._v(" "),
                  _vm._l(_vm.types, function (type) {
                    return _c(
                      "option",
                      { key: type[0], domProps: { value: type[0] } },
                      [_vm._v(_vm._s(type[1]))]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mb-1 mb-xl-0",
              class: { "col-xl-3": query.type, "col-xl-8": !query.type },
            },
            [
              _c("div", { staticClass: "input-group input-group-sm" }, [
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v(_vm._s(_vm.$t("Key"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: query.key,
                      expression: "query.key",
                    },
                  ],
                  staticClass: "form-control",
                  domProps: { value: query.key },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.search.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(query, "key", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      attrs: {
                        type: "button",
                        disabled: !query.key,
                        title: _vm.$t("Toggle exact match"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleQuotation(query, "key")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-solid fa-quote-left" })]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          query.type === "str"
            ? _c("div", { staticClass: "col-xl-5 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.$t("Value"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: query.str,
                        expression: "query.str",
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: query.str },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(query, "str", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light",
                        attrs: {
                          type: "button",
                          disabled: !query.str,
                          title: _vm.$t("Toggle exact match"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleQuotation(query, "str")
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa-solid fa-quote-left" })]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "numeric"
            ? _c("div", { staticClass: "col-xl-3 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c(
                    "div",
                    { staticClass: "input-group input-group-sm mr-1" },
                    [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.numeric.min,
                            expression: "query.numeric.min",
                          },
                        ],
                        staticClass: "form-control",
                        domProps: { value: query.numeric.min },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(query.numeric, "min", $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group input-group-sm ml-1" },
                    [
                      _vm._m(1, true),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.numeric.max,
                            expression: "query.numeric.max",
                          },
                        ],
                        staticClass: "form-control",
                        domProps: { value: query.numeric.max },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(query.numeric, "max", $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "numeric"
            ? _c("div", { staticClass: "col-xl-2 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.$t("Unit"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: query.numeric.unit,
                        expression: "query.numeric.unit",
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: query.numeric.unit },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(query.numeric, "unit", $event.target.value)
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "bool"
            ? _c("div", { staticClass: "col-xl-5 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.$t("Value"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: query.bool,
                          expression: "query.bool",
                        },
                      ],
                      staticClass: "custom-select",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            query,
                            "bool",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "true" } }, [
                        _vm._v("true"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "false" } }, [
                        _vm._v("false"),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "date"
            ? _c("div", { staticClass: "col-xl-5 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c(
                    "div",
                    { staticClass: "input-group input-group-sm mr-1" },
                    [
                      _vm._m(2, true),
                      _vm._v(" "),
                      _c("date-time-picker", {
                        attrs: { "initial-value": query.date.min },
                        on: {
                          input: function ($event) {
                            query.date.min = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group input-group-sm ml-1" },
                    [
                      _vm._m(3, true),
                      _vm._v(" "),
                      _c("date-time-picker", {
                        attrs: { "initial-value": query.date.max },
                        on: {
                          input: function ($event) {
                            query.date.max = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-1 btn-group btn-group-sm" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button", title: _vm.$t("Add search field") },
                on: {
                  click: function ($event) {
                    return _vm.addQuery(null, index)
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-plus" })]
            ),
            _vm._v(" "),
            _vm.queries.length > 1
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: {
                      type: "button",
                      title: _vm.$t("Remove search field"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeQuery(index)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-xmark" })]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("≥")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("≤")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("≥")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("≤")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }