var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card-deck", {
    attrs: { items: _vm.resources, "max-cards": 2 },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            props.item.pretty_type
              ? _c("div", { staticClass: "card-header py-1" }, [
                  _c("strong", [_vm._v(_vm._s(props.item.pretty_type))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card-body py-3" }, [
              _c(
                "a",
                {
                  staticClass: "text-default stretched-link",
                  attrs: { href: props.item._links.view },
                },
                [
                  _c("resource-type", {
                    staticClass: "float-right badge-mt-plus ml-3",
                    attrs: {
                      type: props.item.type,
                      "is-template": Boolean(props.item.data),
                    },
                  }),
                  _vm._v(" "),
                  props.item._links.image
                    ? _c("img", {
                        staticClass:
                          "img-max-100 img-thumbnail float-right ml-3",
                        attrs: {
                          loading: "lazy",
                          src: props.item._links.image,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [{ name: "trim-ws", rawName: "v-trim-ws" }],
                      staticClass: "d-flow-root",
                    },
                    [
                      _c(
                        "small",
                        [
                          _c("resource-visibility", {
                            attrs: { visibility: props.item.visibility },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("strong", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(props.item.title)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("@" + _vm._s(props.item.identifier))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2" }, [
                    props.item.plain_description
                      ? _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.kadi.utils.truncate(
                                  props.item.plain_description,
                                  150
                                )
                              ) +
                              "\n          "
                          ),
                        ])
                      : _c("em", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("No description."))),
                        ]),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer py-1" }, [
              _c(
                "small",
                { staticClass: "text-muted" },
                [
                  _vm._v("\n        " + _vm._s(_vm.$t("Last modified")) + " "),
                  _c("from-now", {
                    attrs: { timestamp: props.item.last_modified },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }