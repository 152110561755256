var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    staticClass: "btn btn-primary",
    attrs: { type: "submit", name: _vm.field.name },
    domProps: { value: _vm.field.label },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }