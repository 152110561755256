var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("confirm-dialog", { ref: "dialog" }),
      _vm._v(" "),
      _c("dynamic-pagination", {
        ref: "pagination",
        attrs: {
          endpoint: _vm.endpoint,
          placeholder: _vm.placeholder,
          "per-page": _vm.perPage,
          "enable-filter": _vm.enableFilter,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.title))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge-total" }, [
                    _vm._v(_vm._s(props.total)),
                  ]),
                ]),
                _vm._v(" "),
                props.total > 0
                  ? _c(
                      "ul",
                      { staticClass: "list-group" },
                      _vm._l(props.items, function (link) {
                        return _c(
                          "li",
                          { key: link.id, staticClass: "list-group-item py-1" },
                          [
                            _c(
                              "div",
                              { staticClass: "row align-items-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-lg-4 mb-2 mb-lg-0" },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: link._links.view } },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(link.name)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-5 mb-2 mb-lg-0" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            _vm.direction === "out"
                                              ? link.record_to._links.view
                                              : link.record_from._links.view,
                                        },
                                      },
                                      [
                                        _c("basic-resource-info", {
                                          attrs: {
                                            resource:
                                              _vm.direction === "out"
                                                ? link.record_to
                                                : link.record_from,
                                            "show-description": false,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-lg-3 mb-2 mb-lg-0 d-lg-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "btn-group btn-group-sm" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-light",
                                            attrs: { href: link._links.edit },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa-solid fa-pencil",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Edit")) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            attrs: {
                                              type: "button",
                                              title: _vm.$t("Remove link"),
                                              disabled: link.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeLink(link)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa-solid fa-trash",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }