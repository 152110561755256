var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body filter-container" }, [
      _vm.showRecordsFilter
        ? _c("div", { staticClass: "form-check my-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.records,
                  expression: "filter.records",
                },
              ],
              staticClass: "form-check-input",
              attrs: { id: `records-${_vm.suffix}`, type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.filter.records)
                  ? _vm._i(_vm.filter.records, null) > -1
                  : _vm.filter.records,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.filter.records,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.filter, "records", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.filter,
                          "records",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.filter, "records", $$c)
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: `records-${_vm.suffix}` },
              },
              [_vm._v(_vm._s(_vm.$t("Exclude records")))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showExportFilter
        ? _c("div", { staticClass: "my-2" }, [
            _c("div", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.$t("Include export data"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card bg-light" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                _vm._l(
                  [
                    ["json", "JSON"],
                    ["rdf", "RDF (Turtle)"],
                    ["pdf", "PDF"],
                  ],
                  function (exportMeta) {
                    return _c(
                      "div",
                      { key: exportMeta[0], staticClass: "form-check" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.crateExportData[exportMeta[0]],
                              expression:
                                "filter.crateExportData[exportMeta[0]]",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            id: `export-type-${exportMeta[0]}-${_vm.suffix}`,
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.filter.crateExportData[exportMeta[0]]
                            )
                              ? _vm._i(
                                  _vm.filter.crateExportData[exportMeta[0]],
                                  null
                                ) > -1
                              : _vm.filter.crateExportData[exportMeta[0]],
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.filter.crateExportData[exportMeta[0]],
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.filter.crateExportData,
                                      exportMeta[0],
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.filter.crateExportData,
                                      exportMeta[0],
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.filter.crateExportData,
                                  exportMeta[0],
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: {
                              for: `export-type-${exportMeta[0]}-${_vm.suffix}`,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(exportMeta[1]) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showUserFilter
        ? _c("div", { staticClass: "form-check my-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.user,
                  expression: "filter.user",
                },
              ],
              staticClass: "form-check-input",
              attrs: { id: `user-${_vm.suffix}`, type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.filter.user)
                  ? _vm._i(_vm.filter.user, null) > -1
                  : _vm.filter.user,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.filter.user,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.filter, "user", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.filter,
                          "user",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.filter, "user", $$c)
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: `user-${_vm.suffix}` },
              },
              [_vm._v(_vm._s(_vm.$t("Exclude user information")))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showLinksFilter
        ? _c("div", { staticClass: "my-2" }, [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: `links-${_vm.suffix}` },
              },
              [_vm._v(_vm._s(_vm.$t("Exclude record links")))]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter.links,
                    expression: "filter.links",
                  },
                ],
                staticClass: "custom-select custom-select-sm",
                attrs: { id: `links-${_vm.suffix}` },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.filter,
                      "links",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "out" } }, [
                  _vm._v(_vm._s(_vm.$t("Outgoing"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "in" } }, [
                  _vm._v(_vm._s(_vm.$t("Incoming"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "both" } }, [
                  _vm._v(_vm._s(_vm.$t("Both"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showExtrasFormatFilter
        ? _c("div", { staticClass: "my-2" }, [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: `format-${_vm.suffix}` },
              },
              [_vm._v(_vm._s(_vm.$t("Extra metadata format")))]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter.extrasFormat,
                    expression: "filter.extrasFormat",
                  },
                ],
                staticClass: "custom-select custom-select-sm",
                attrs: { id: `format-${_vm.suffix}` },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.filter,
                      "extrasFormat",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "standard" } }, [
                  _vm._v(_vm._s(_vm.$t("Standard"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "plain" } }, [
                  _vm._v(_vm._s(_vm.$t("Plain"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showExtrasFilter
        ? _c("div", { staticClass: "my-2" }, [
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-md-6 mb-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("Exclude extra metadata"))),
              ]),
              _vm._v(" "),
              _vm.showExtrasPropagationFilter
                ? _c(
                    "div",
                    { staticClass: "col-md-6 d-md-flex justify-content-end" },
                    [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.propagate,
                              expression: "filter.propagate",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            id: `propagate-${_vm.suffix}`,
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.filter.propagate)
                              ? _vm._i(_vm.filter.propagate, null) > -1
                              : _vm.filter.propagate,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.filter.propagate,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.filter,
                                      "propagate",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.filter,
                                      "propagate",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.filter, "propagate", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: `propagate-${_vm.suffix}` },
                          },
                          [_vm._v(_vm._s(_vm.$t("Apply to linked records")))]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card bg-light" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("extras-selector", {
                    attrs: { extras: _vm.extras },
                    on: {
                      select: function ($event) {
                        _vm.filter.extras = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }