var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "card bg-light max-vh-75 overflow-auto": _vm.depth === 0 } },
    [
      _c("div", { class: { "mt-3 mr-4": _vm.depth === 0 } }, [
        _c(
          "ul",
          { style: _vm.listWidth },
          _vm._l(_vm.entries_, function (entry) {
            return _c("li", { key: entry.id }, [
              !entry.is_dir
                ? _c(
                    "div",
                    {
                      staticClass: "entry d-flex justify-content-between px-1",
                    },
                    [
                      _c("div", { staticClass: "text-break" }, [
                        _c("i", { staticClass: "fa-solid fa-file" }),
                        _vm._v(" " + _vm._s(entry.name) + "\n          "),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "size text-right" }, [
                        _vm._v(_vm._s(_vm.kadi.utils.filesize(entry.size))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              entry.is_dir
                ? _c(
                    "div",
                    [
                      _c(
                        "collapse-item",
                        {
                          staticClass: "px-1",
                          attrs: {
                            id: entry.id,
                            "show-icon-class": "fa-solid fa-folder",
                            "hide-icon-class": "fa-solid fa-folder-open",
                          },
                        },
                        [_c("strong", [_vm._v(_vm._s(entry.name))])]
                      ),
                      _vm._v(" "),
                      _c("archive-viewer", {
                        attrs: {
                          id: entry.id,
                          entries: entry.children,
                          depth: _vm.depth + 1,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }