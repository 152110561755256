var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dynamic-pagination", {
    attrs: {
      endpoint: _vm.endpoint,
      args: { sort: _vm.sort },
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (paginationProps) {
          return [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-md-6 col-xl-8" }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.title))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge-total" }, [
                    _vm._v(_vm._s(paginationProps.total)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              paginationProps.totalUnfiltered > 1
                ? _c("div", { staticClass: "col-md-6 col-xl-4 mb-3 mb-md-2" }, [
                    _c("div", { staticClass: "input-group input-group-sm" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-group-text",
                            attrs: { for: `sort-${_vm.id}` },
                          },
                          [_vm._v(_vm._s(_vm.$t("Sort by")))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.sort,
                              expression: "sort",
                            },
                          ],
                          staticClass: "custom-select",
                          attrs: { id: `sort-${_vm.id}` },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.sort = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.sortOptions, function (option) {
                          return _c(
                            "option",
                            { key: option[0], domProps: { value: option[0] } },
                            [_vm._v(_vm._s(option[1]))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("card-deck", {
              attrs: { items: paginationProps.items },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("div", { staticClass: "card-body py-2" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-default stretched-link",
                              attrs: { href: props.item._links.view },
                            },
                            [
                              _c("strong", { staticClass: "font-italic" }, [
                                _vm._v(_vm._s(props.item.name)),
                              ]),
                              _vm._v(" "),
                              _c("hr", { staticClass: "mt-1 mb-2" }),
                              _vm._v(" "),
                              _c("resource-type", {
                                staticClass: "float-right badge-mt-plus ml-3",
                                attrs: {
                                  type: _vm.getLinkedRecord(props.item).type,
                                },
                              }),
                              _vm._v(" "),
                              _c("basic-resource-info", {
                                attrs: {
                                  resource: _vm.getLinkedRecord(props.item),
                                  "show-description": false,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-footer py-1" }, [
                          _c(
                            "small",
                            { staticClass: "text-muted" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("Last modified")) +
                                  " "
                              ),
                              _c("from-now", {
                                attrs: { timestamp: props.item.last_modified },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-footer elevated py-1" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-link text-primary p-0",
                                    attrs: {
                                      href: _vm.getLinkedRecord(props.item)
                                        ._links.view,
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa-solid fa-eye" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("View record")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                props.item._links.edit
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-link text-primary p-0",
                                        attrs: { href: props.item._links.edit },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa-solid fa-pencil",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Edit link")) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }