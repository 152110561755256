var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("confirm-dialog", { ref: "dialog" }),
      _vm._v(" "),
      _c("div", { staticClass: "card bg-light" }, [
        _vm.currentSearch
          ? _c("div", { staticClass: "card-header py-2" }, [
              _vm.state !== "edit"
                ? _c("div", { staticClass: "form-row align-items-center" }, [
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _vm.unsavedChanges
                          ? _c(
                              "tooltip-item",
                              {
                                staticClass: "mr-1",
                                attrs: { title: _vm.$t("Unsaved changes") },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa-solid fa-triangle-exclamation",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("Current search")) +
                            ":\n          "
                        ),
                        _c("strong", { staticClass: "mr-3" }, [
                          _vm._v(_vm._s(_vm.currentSearch.name)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-4 d-flex justify-content-end" },
                      [
                        _c("div", { staticClass: "btn-group btn-group-sm" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light fixed-btn",
                              attrs: {
                                type: "button",
                                title: _vm.$t("Edit search"),
                                disabled: _vm.deleting,
                              },
                              on: { click: _vm.startEditing },
                            },
                            [_c("i", { staticClass: "fa-solid fa-pencil" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light fixed-btn",
                              attrs: {
                                type: "button",
                                title: _vm.$t("Deselect search"),
                                disabled: _vm.deleting,
                              },
                              on: { click: _vm.unselectSearch },
                            },
                            [_c("i", { staticClass: "fa-solid fa-xmark" })]
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _c("div", { staticClass: "input-group input-group-sm" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.editSearchName,
                          expression: "editSearchName",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.editSearchName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.editSearchName = $event.target.value.trim()
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light fixed-btn",
                          attrs: { type: "button", title: _vm.$t("Back") },
                          on: { click: _vm.finishEditing },
                        },
                        [_c("i", { staticClass: "fa-solid fa-angle-left" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary fixed-btn",
                          attrs: {
                            type: "button",
                            title: _vm.$t("Save"),
                            disabled: !_vm.editSearchName,
                          },
                          on: { click: _vm.editSearch },
                        },
                        [_c("i", { staticClass: "fa-solid fa-floppy-disk" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger fixed-btn",
                          attrs: {
                            type: "button",
                            title: _vm.$t("Remove search"),
                          },
                          on: { click: _vm.removeSearch },
                        },
                        [_c("i", { staticClass: "fa-solid fa-trash" })]
                      ),
                    ]),
                  ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "card-body py-3" }, [
          _vm.state !== "save"
            ? _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-9" },
                  [
                    _c("dynamic-selection", {
                      attrs: {
                        "container-classes": "select2-single-sm",
                        endpoint: _vm.selectEndpoint,
                        "request-params": { object: _vm.objectType },
                        placeholder: _vm.$t("Select a saved search"),
                        "reset-on-select": true,
                        disabled: _vm.deleting,
                      },
                      on: {
                        select: function ($event) {
                          return _vm.loadSearch($event.id)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-block btn-light",
                      attrs: {
                        type: "button",
                        title: _vm.$t("New search"),
                        disabled: _vm.deleting,
                      },
                      on: {
                        click: function ($event) {
                          _vm.state = "save"
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-solid fa-plus" })]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "input-group input-group-sm" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.newSearchName,
                      expression: "newSearchName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: _vm.$t("Name") },
                  domProps: { value: _vm.newSearchName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newSearchName = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light fixed-btn",
                      attrs: { type: "button", title: _vm.$t("Back") },
                      on: { click: _vm.finishSaving },
                    },
                    [_c("i", { staticClass: "fa-solid fa-angle-left" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary fixed-btn",
                      attrs: {
                        type: "button",
                        title: _vm.$t("Save"),
                        disabled: !_vm.newSearchName,
                      },
                      on: { click: _vm.saveSearch },
                    },
                    [_c("i", { staticClass: "fa-solid fa-floppy-disk" })]
                  ),
                ]),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }