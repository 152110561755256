<!-- Copyright 2021 Karlsruhe Institute of Technology
   -
   - Licensed under the Apache License, Version 2.0 (the "License");
   - you may not use this file except in compliance with the License.
   - You may obtain a copy of the License at
   -
   -     http://www.apache.org/licenses/LICENSE-2.0
   -
   - Unless required by applicable law or agreed to in writing, software
   - distributed under the License is distributed on an "AS IS" BASIS,
   - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   - See the License for the specific language governing permissions and
   - limitations under the License. -->

<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-4 mb-2 mb-sm-0">
        <a class="btn btn-sm btn-light" :href="downloadEndpoint">
          <i class="fa-solid fa-download"></i>
          {{ $t('Download') }}
        </a>
      </div>
      <div v-if="isCustomizable" class="col-sm-8 d-sm-flex justify-content-end">
        <button v-if="showUpdateButton"
                type="button"
                class="btn btn-sm btn-light mr-2"
                :disabled="loading"
                @click="updatePreview">
          <i class="fa-solid fa-eye"></i>
          {{ $t('Update preview') }}
        </button>
        <collapse-item :id="`collapse-${suffix}`"
                       :is-collapsed="true"
                       class="btn btn-sm btn-light"
                       @collapse="showUpdateButton = !$event">
          {{ $t('Customize') }}
        </collapse-item>
      </div>
    </div>
    <div v-if="isCustomizable" :id="`collapse-${suffix}`" class="mb-4">
      <resource-export-filter :resource-type="resourceType"
                              :export-type="exportType"
                              :extras="extras"
                              :allow-extras-propagation="allowsExtrasPropagation"
                              @filter="filter = $event">
      </resource-export-filter>
    </div>
    <div v-if="!loading" ref="preview">
      <div v-if="hasTextPreview">
        <div class="card bg-light">
          <div class="mt-3 ml-3">
            <pre class="max-vh-75 mb-0 pb-3">{{ exportData }}</pre>
          </div>
        </div>
      </div>
      <div v-else-if="exportType === 'pdf'">
        <iframe class="w-100 vh-75 border rounded" frameborder="0" allowfullscreen :src="exportData">
        </iframe>
      </div>
      <div v-else-if="exportType === 'qr'">
        <div class="border bg-light text-center">
          <img class="img-fluid" :src="exportData">
        </div>
      </div>
    </div>
    <i v-else class="fa-solid fa-circle-notch fa-spin"></i>
  </div>
</template>

<script>
export default {
  props: {
    resourceType: String,
    exportType: String,
    endpoint: String,
    extras: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      suffix: kadi.utils.randomAlnum(),
      exportData: null,
      loading: true,
      showUpdateButton: false,
      filter: {},
    };
  },
  computed: {
    downloadEndpoint() {
      return `${this.endpoint}?download=true&filter=${JSON.stringify(this.filter)}`;
    },
    isCustomizable() {
      return this.exportType !== 'qr';
    },
    allowsExtrasPropagation() {
      return ['json', 'ro-crate'].includes(this.exportType);
    },
    hasTextPreview() {
      return ['json', 'json-schema', 'rdf', 'ro-crate', 'shacl'].includes(this.exportType);
    },
  },
  mounted() {
    this.updateExportData();
  },
  methods: {
    async updateExportData(scrollIntoView = false) {
      this.loading = true;

      try {
        const params = {filter: JSON.stringify(this.filter)};
        const response = await axios.get(this.endpoint, {params});

        this.exportData = response.data;
        this.loading = false;

        if (scrollIntoView) {
          await this.$nextTick();
          kadi.utils.scrollIntoView(this.$refs.preview, 'top');
        }
      } catch (error) {
        kadi.base.flashDanger($t('Error loading export data.'), {request: error.request});
      }
    },
    updatePreview() {
      this.updateExportData(true);
    },
  },
};
</script>
