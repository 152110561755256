var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.data,
              expression: "data",
            },
          ],
          class: _vm.classes,
          attrs: {
            id: _vm.field.id,
            type: "checkbox",
            name: _vm.field.name,
            disabled: _vm.disabled,
          },
          domProps: {
            checked: Array.isArray(_vm.data)
              ? _vm._i(_vm.data, null) > -1
              : _vm.data,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.data,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.data = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.data = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.data = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: _vm.field.id } },
          [
            _vm._t("default", function () {
              return [_vm._v(_vm._s(_vm.field.label))]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.field.errors, function (error) {
        return _c("div", { key: error, staticClass: "invalid-feedback mt-0" }, [
          _vm._v(_vm._s(error)),
        ])
      }),
      _vm._v(" "),
      _vm.field.errors.length === 0
        ? _c("small", { staticClass: "form-text text-muted mt-0" }, [
            _vm._v(_vm._s(_vm.field.description)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }