var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: { "mt-2": _vm.nestedType && _vm.extras.length > 0 } },
      [
        _c(
          "vue-draggable",
          {
            attrs: {
              handle: ".sort-handle",
              "scroll-sensitivity": "100",
              "scroll-speed": "15",
              list: _vm.extras,
              group: _vm.editingMode ? "extras" : _vm.draggableGroup,
              "force-fallback": true,
              "empty-insert-threshold": 35,
            },
            on: { start: _vm.startDrag, end: _vm.endDrag },
          },
          _vm._l(_vm.extras, function (extra, index) {
            return _c(
              "div",
              { key: extra.id },
              [
                _c("extras-editor-item", {
                  class: index < _vm.extras.length - 1 ? "mb-3" : "mb-2",
                  attrs: {
                    extra: extra,
                    index: index,
                    "template-endpoint": _vm.templateEndpoint,
                    "enable-term-search": _vm.enableTermSearch,
                    "editing-mode": _vm.editingMode,
                    "nested-type": _vm.nestedType,
                    depth: _vm.depth,
                  },
                  on: {
                    "add-extra": function ($event) {
                      return _vm.addExtra(null, index, true)
                    },
                    "remove-extra": function ($event) {
                      return _vm.removeExtra(extra)
                    },
                    "duplicate-extra": function ($event) {
                      return _vm.duplicateExtra(extra)
                    },
                    "init-nested-value": function ($event) {
                      return _vm.initNestedValue(extra, $event)
                    },
                    "show-term-search": function ($event) {
                      return _vm.$emit("show-term-search", $event)
                    },
                    "save-checkpoint": function ($event) {
                      return _vm.$emit("save-checkpoint")
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-row align-items-center" }, [
      _c("div", { staticClass: "col-md-3" }, [
        _vm.editingMode || _vm.nestedType === "list"
          ? _c(
              "button",
              {
                staticClass: "btn btn-sm btn-link text-primary p-0 mt-2",
                attrs: { type: "button", tabindex: "-1" },
                on: {
                  click: function ($event) {
                    return _vm.addExtra(null, null, true)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa-solid fa-plus mr-1" }),
                _vm._v(" " + _vm._s(_vm.$t("Add extra")) + "\n      "),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-9" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }