var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showLangPrompt
    ? _c("div", { staticClass: "card bg-light" }, [
        _c("div", { staticClass: "card-body py-3" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-lg-9" }, [
              _vm._v(
                "\n        Based on your browser settings, you seem to prefer a different language. Do you want to change the current\n        language?\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-lg-3 mt-2 mt-lg-0 d-lg-flex justify-content-end",
              },
              [
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.acceptPrompt },
                    },
                    [_vm._v("Yes")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      attrs: { type: "button" },
                      on: { click: _vm.dismissPrompt },
                    },
                    [_vm._v("No")]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }