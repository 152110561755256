var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group", attrs: { tabindex: "-1" } }, [
    _c(
      "div",
      { staticClass: "form-row mr-0", class: { drag: _vm.extra.isDragging } },
      [
        _c(
          "div",
          {
            staticClass: "custom-col-2 custom-mb",
            class: { "custom-mr": _vm.nestedType },
          },
          [
            _c("div", { staticClass: "input-group input-group-sm" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c("span", { staticClass: "input-group-text" }, [
                  _vm._v(_vm._s(_vm.$t("Type"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.extra.type.value,
                      expression: "extra.type.value",
                    },
                  ],
                  staticClass: "custom-select",
                  class: {
                    "has-error":
                      _vm.extra.type.errors.length > 0 && !_vm.extra.isDragging,
                  },
                  attrs: {
                    disabled:
                      !_vm.editingMode ||
                      (_vm.hasOptions && !_vm.extra.editValidation),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.extra.type,
                          "value",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.changeType,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "str" } }, [_vm._v("String")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "int" } }, [
                    _vm._v("Integer"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "float" } }, [
                    _vm._v("Float"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "bool" } }, [
                    _vm._v("Boolean"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "date" } }, [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "dict" } }, [
                    _vm._v("Dictionary"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "list" } }, [_vm._v("List")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.type.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "custom-col-4 custom-mb",
            class: { "custom-mr": _vm.nestedType },
          },
          [
            _c(
              "div",
              { staticClass: "input-group input-group-sm" },
              [
                _vm.extra.description.value
                  ? _c(
                      "tooltip-item",
                      {
                        staticClass: "input-group-prepend",
                        attrs: { title: _vm.extra.description.value },
                      },
                      [
                        _c("span", { staticClass: "input-group-text" }, [
                          _c("i", {
                            staticClass: "fa-solid fa-circle-info text-default",
                          }),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.extra.term.value
                  ? _c(
                      "tooltip-item",
                      {
                        staticClass:
                          "input-group-prepend stretched-link-container",
                        attrs: { title: _vm.extra.term.value },
                      },
                      [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm.kadi.utils.isHttpUrl(_vm.extra.term.value)
                            ? _c(
                                "a",
                                {
                                  staticClass: "stretched-link",
                                  attrs: {
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                    tabindex: "-1",
                                    href: _vm.extra.term.value,
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa-solid fa-link text-default",
                                  }),
                                ]
                              )
                            : _c("i", {
                                staticClass: "fa-solid fa-link text-default",
                              }),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v(_vm._s(_vm.$t("Key"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("input", {
                  staticClass: "form-control",
                  class: [
                    _vm.keyInputClass,
                    {
                      "has-error":
                        _vm.extra.key.errors.length > 0 &&
                        !_vm.extra.isDragging,
                      "font-weight-bold": _vm.isNestedType,
                    },
                  ],
                  attrs: {
                    readonly: !_vm.editingMode || _vm.isInList,
                    tabindex: !_vm.editingMode || _vm.isInList ? -1 : 0,
                  },
                  domProps: { value: _vm.keyModel },
                  on: {
                    change: function ($event) {
                      return _vm.changeString("key", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.editingMode
                  ? _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "input-group-text btn btn-light",
                          class: { "toggle-active": _vm.extra.editDetails },
                          attrs: {
                            type: "button",
                            tabindex: "-1",
                            title: _vm.$t("Additional settings"),
                          },
                          on: { click: _vm.editDetails },
                        },
                        [
                          _vm.extra.editDetails
                            ? _c("i", { staticClass: "fa-solid fa-angle-up" })
                            : _c("i", {
                                staticClass: "fa-solid fa-angle-down",
                              }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.key.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "custom-mb", class: _vm.valueContainerClasses },
          [
            _c(
              "div",
              { staticClass: "input-group input-group-sm" },
              [
                _vm.valueTooltip
                  ? _c(
                      "tooltip-item",
                      {
                        staticClass: "input-group-prepend",
                        attrs: { title: _vm.valueTooltip },
                      },
                      [
                        _c("span", { staticClass: "input-group-text" }, [
                          _c("i", {
                            staticClass: "fa-solid fa-circle-info text-default",
                          }),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("\n            " + _vm._s(_vm.$t("Value")) + " "),
                    _vm.isRequired
                      ? _c("strong", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.hasOptions &&
                !_vm.hasTemplateSelection &&
                !["bool", "date"].includes(_vm.extra.type.value)
                  ? _c("input", {
                      staticClass: "form-control",
                      class: [
                        _vm.valueInputClass,
                        {
                          "has-error":
                            _vm.extra.value.errors.length > 0 &&
                            !_vm.extra.isDragging,
                        },
                      ],
                      attrs: {
                        readonly: _vm.isNestedType,
                        tabindex: _vm.isNestedType ? -1 : 0,
                      },
                      domProps: { value: _vm.valueModel },
                      on: {
                        change: function ($event) {
                          return _vm.changeValue($event.target.value)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasOptions && _vm.extra.type.value === "bool"
                  ? _c(
                      "select",
                      {
                        staticClass: "custom-select",
                        class: [
                          _vm.valueInputClass,
                          {
                            "has-error":
                              _vm.extra.value.errors.length > 0 &&
                              !_vm.extra.isDragging,
                          },
                        ],
                        domProps: { value: _vm.valueModel },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue($event.target.value)
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "true" } }, [
                          _vm._v("true"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "false" } }, [
                          _vm._v("false"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.extra.type.value === "date"
                  ? _c("input", {
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.extra.value.value },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.extra.type.value === "date"
                  ? _c("date-time-picker", {
                      class: [
                        _vm.valueInputClass,
                        {
                          "has-error":
                            _vm.extra.value.errors.length > 0 &&
                            !_vm.extra.isDragging,
                        },
                      ],
                      attrs: { "initial-value": _vm.extra.value.value },
                      on: { input: _vm.changeValue },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasOptions
                  ? _c(
                      "select",
                      {
                        staticClass: "custom-select",
                        class: [
                          _vm.valueInputClass,
                          {
                            "has-error":
                              _vm.extra.value.errors.length > 0 &&
                              !_vm.extra.isDragging,
                          },
                        ],
                        domProps: { value: _vm.valueModel },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue($event.target.value)
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.extra.validation.value.options,
                          function (option) {
                            return _c(
                              "option",
                              {
                                key: option,
                                domProps: { value: _vm.getOptionValue(option) },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.getOptionValue(option)) +
                                    "\n          "
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasTemplateSelection
                  ? _c("dynamic-selection", {
                      class: _vm.valueInputClass,
                      attrs: {
                        "container-classes": "select2-single-sm",
                        placeholder: _vm.$t("Select a template"),
                        endpoint: _vm.templateEndpoint,
                        "reset-on-select": true,
                      },
                      on: { select: _vm.selectTemplate },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.editingMode && !_vm.isNestedType
                  ? _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "input-group-text btn btn-light",
                          class: { "toggle-active": _vm.extra.editValidation },
                          attrs: {
                            type: "button",
                            tabindex: "-1",
                            title: _vm.$t("Validation"),
                          },
                          on: { click: _vm.editValidation },
                        },
                        [
                          _vm.extra.editValidation
                            ? _c("i", { staticClass: "fa-solid fa-angle-up" })
                            : _c("i", {
                                staticClass: "fa-solid fa-angle-down",
                              }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.value.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isNumericType,
                expression: "isNumericType",
              },
            ],
            staticClass: "custom-col-2 custom-mb",
            class: { "custom-mr": _vm.nestedType },
          },
          [
            _c("div", { staticClass: "input-group input-group-sm" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c("span", { staticClass: "input-group-text" }, [
                  _vm._v(_vm._s(_vm.$t("Unit"))),
                ]),
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                class: {
                  "has-error":
                    _vm.extra.unit.errors.length > 0 && !_vm.extra.isDragging,
                },
                domProps: { value: _vm.extra.unit.value },
                on: {
                  change: function ($event) {
                    return _vm.changeString("unit", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.unit.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _vm.editingMode || _vm.isInList
          ? _c(
              "div",
              {
                staticClass: "custom-col-2",
                class: { "custom-mr": _vm.nestedType },
              },
              [
                _c("div", { staticClass: "btn-group btn-group-sm w-100" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      attrs: {
                        type: "button",
                        tabindex: "-1",
                        title: `${_vm.$t("Add extra")} (${_vm.$t("Ctrl")}+I)`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("add-extra")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-solid fa-plus" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      attrs: {
                        type: "button",
                        tabindex: "-1",
                        title: _vm.$t("Remove extra"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("remove-extra")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-solid fa-xmark" })]
                  ),
                  _vm._v(" "),
                  _vm.editingMode
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: {
                            type: "button",
                            tabindex: "-1",
                            title: _vm.$t("Duplicate extra"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("duplicate-extra")
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa-solid fa-copy" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "btn btn-light disabled sort-handle",
                      attrs: { tabindex: "-1", title: _vm.$t("Move extra") },
                    },
                    [_c("i", { staticClass: "fa-solid fa-bars" })]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.editingMode && _vm.extra.editDetails && !_vm.extra.isDragging,
            expression: "editingMode && extra.editDetails && !extra.isDragging",
          },
        ],
        staticClass: "mt-1 mr-1",
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "mx-2" }, [
            _c("div", { staticClass: "form-row align-items-center my-2" }, [
              _c("div", { staticClass: "col-md-2 text-muted" }, [
                _c("small", [_vm._v(_vm._s(_vm.$t("Description")))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-10" },
                [
                  _c("textarea", {
                    staticClass: "form-control form-control-sm description",
                    class: {
                      "has-error": _vm.extra.description.errors.length > 0,
                    },
                    attrs: { spellcheck: "false", rows: "3" },
                    domProps: { value: _vm.extra.description.value },
                    on: {
                      change: function ($event) {
                        return _vm.changeString(
                          "description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.extra.description.errors, function (error) {
                    return _c(
                      "div",
                      { key: error, staticClass: "invalid-feedback" },
                      [_vm._v(_vm._s(error))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row align-items-center my-2" }, [
              _c("div", { staticClass: "col-md-2 text-muted" }, [
                _c("small", [_vm._v(_vm._s(_vm.$t("Term IRI")))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-10" },
                [
                  _c("div", { staticClass: "input-group input-group-sm" }, [
                    _c("input", {
                      staticClass: "form-control",
                      class: { "has-error": _vm.extra.term.errors.length > 0 },
                      domProps: { value: _vm.extra.term.value },
                      on: {
                        change: function ($event) {
                          return _vm.changeString("term", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.enableTermSearch
                      ? _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "show-term-search",
                                    _vm.extra
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa-solid fa-search" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Find term")) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.extra.term.errors, function (error) {
                    return _c(
                      "div",
                      { key: error, staticClass: "invalid-feedback" },
                      [_vm._v(_vm._s(error))]
                    )
                  }),
                  _vm._v(" "),
                  _vm.extra.term.errors.length === 0
                    ? _c("small", { staticClass: "form-text text-muted" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "An IRI specifying an existing term that the metadatum should represent."
                              )
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.editingMode &&
              _vm.extra.editValidation &&
              !_vm.isNestedType &&
              !_vm.extra.isDragging,
            expression:
              "editingMode && extra.editValidation && !isNestedType && !extra.isDragging",
          },
        ],
        staticClass: "mt-1 mr-1",
      },
      [
        _c("extras-editor-item-validation", {
          class: { "has-error": _vm.extra.validation.errors.length > 0 },
          attrs: {
            type: _vm.extra.type.value,
            "convert-value": _vm.convertValue,
            "initial-validation": _vm.extra.validation.value,
          },
          on: { validate: _vm.validate },
        }),
        _vm._v(" "),
        _vm._l(_vm.extra.validation.errors, function (error) {
          return _c("div", { key: error, staticClass: "invalid-feedback" }, [
            _vm._v(_vm._s(error)),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.isNestedType
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.extra.isDragging,
                expression: "!extra.isDragging",
              },
            ],
            staticClass: "card mt-1 pl-3 py-2 extras",
            class: { even: _vm.depth % 2 == 0, nested: _vm.depth > 0 },
          },
          [
            _c("extras-editor-items", {
              attrs: {
                extras: _vm.extra.value.value,
                "template-endpoint": _vm.templateEndpoint,
                "enable-term-search": _vm.enableTermSearch,
                "editing-mode": _vm.editingMode,
                "nested-type": _vm.extra.type.value,
                depth: _vm.depth + 1,
              },
              on: {
                "show-term-search": function ($event) {
                  return _vm.$emit("show-term-search", $event)
                },
                "save-checkpoint": function ($event) {
                  return _vm.$emit("save-checkpoint")
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }