var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dialog", staticClass: "modal", attrs: { tabindex: "-1" } },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "div",
            { staticClass: "modal-header d-flex align-items-center py-2" },
            [
              _c("span", { staticClass: "modal-title" }, [
                _c("strong", [_vm._v("Score:")]),
                _vm._v(" " + _vm._s(_vm.score) + "\n        "),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: {
                    click: function ($event) {
                      _vm.gameActive = false
                    },
                  },
                },
                [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "menu d-flex flex-column align-items-center" },
              [
                _vm.state === "start"
                  ? _c("h5", [_vm._v("Press [Space Bar]")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.state === "over"
                  ? _c("h4", { staticClass: "font-weight-bold" }, [
                      _vm._v("Game Over"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.state === "over"
                  ? _c("h4", [
                      _vm._v("\n            Rank: "),
                      _c(
                        "strong",
                        { staticClass: "rank", style: { color: _vm.rank[2] } },
                        [_vm._v(_vm._s(_vm.rank[1]))]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("canvas", {
              ref: "canvas",
              staticClass: "border border-primary",
              attrs: { width: _vm.width, height: _vm.height },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }