var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type
    ? _c(
        "span",
        { staticClass: "badge badge-primary font-weight-normal elevated" },
        [
          _vm.isTemplate
            ? _c("span", [_vm._v(_vm._s(_vm.kadi.utils.capitalize(_vm.type)))])
            : _c("span", { attrs: { title: _vm.type } }, [
                _vm._v(_vm._s(_vm.kadi.utils.truncate(_vm.type, 20))),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }