var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-sm btn-light my-1",
      attrs: {
        type: "button",
        title: _vm.title,
        disabled: _vm.disableFavorite,
      },
      on: { click: _vm.toggleFavorite },
    },
    [
      _vm.isFavorite_
        ? _c("i", { staticClass: "fa-solid fa-star" })
        : _c("i", { staticClass: "fa-regular fa-star" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }