var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "row align-items-end" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("label", { staticClass: "form-control-label" }, [
          _vm._v(_vm._s(_vm.field.label)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 d-sm-flex justify-content-end mb-2" },
        [_vm._t("default")],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body p-2" },
        _vm._l(_vm.roles, function (role, index) {
          return _c("div", { key: role.id }, [
            _c(
              "div",
              {
                staticClass: "form-row",
                class: { "mb-3": index < _vm.roles.length - 1 },
              },
              [
                _vm.groupRolesEnabled
                  ? _c("div", { staticClass: "col-md-3 mb-1 mb-md-0" }, [
                      _c("div", { staticClass: "input-group input-group-sm" }, [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v(_vm._s(_vm.$t("Type"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: role.subject_type,
                                expression: "role.subject_type",
                              },
                            ],
                            staticClass: "custom-select",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    role,
                                    "subject_type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  role.subject = null
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.subjectTypes, function (subjectMeta) {
                            return _c(
                              "option",
                              {
                                key: subjectMeta[0],
                                domProps: { value: subjectMeta[0] },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(subjectMeta[1]) +
                                    "\n                "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4 mb-1 mb-md-0",
                    class: { "col-md-7": !_vm.groupRolesEnabled },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group input-group-sm" },
                      [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v(_vm._s(_vm.getSubjectTitle(role))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("dynamic-selection", {
                          key: role.subject_type,
                          attrs: {
                            "container-classes": "select2-single-sm",
                            endpoint: _vm.getSelectionEndpoint(role),
                            "initial-values": _vm.getInitialSubject(role),
                            placeholder: _vm.getSubjectPlaceholder(role),
                          },
                          on: {
                            select: function ($event) {
                              return _vm.selectSubject(role, $event)
                            },
                            unselect: function ($event) {
                              role.subject = null
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 mb-1 mb-md-0" }, [
                  _c("div", { staticClass: "input-group input-group-sm" }, [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c("span", { staticClass: "input-group-text" }, [
                        _vm._v(_vm._s(_vm.$t("Role"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: role.role,
                            expression: "role.role",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              role,
                              "role",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _vm.field.allow_none
                          ? _c("option", { attrs: { value: "" } })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.field.roles, function (roleMeta) {
                          return _c(
                            "option",
                            {
                              key: roleMeta[0],
                              domProps: { value: roleMeta[0] },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(roleMeta[1]) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "btn-group btn-group-sm w-100" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light",
                        attrs: { type: "button", title: _vm.$t("Add role") },
                        on: {
                          click: function ($event) {
                            return _vm.addRole(null, index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa-solid fa-plus" })]
                    ),
                    _vm._v(" "),
                    _vm.roles.length > 1
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-light",
                            attrs: {
                              type: "button",
                              title: _vm.$t("Remove role"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeRole(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa-solid fa-xmark" })]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("small", { staticClass: "form-text text-muted" }, [
      _vm._v(_vm._s(_vm.field.description)),
    ]),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: _vm.field.name },
      domProps: { value: _vm.serializedRoles },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }