var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 mb-2 mb-md-0" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-light",
            attrs: { type: "button", disabled: !_vm.showDiff },
            on: { click: _vm.toggleComparison },
          },
          [
            _c("i", { staticClass: "fa-solid fa-repeat" }),
            _vm._v(" "),
            _vm.compareLatest_
              ? _c("span", [
                  _vm._v(_vm._s(_vm.$t("Compare to previous revision"))),
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$t("Compare to current state"))),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 d-md-flex justify-content-end" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light",
              attrs: { type: "button" },
              on: { click: _vm.toggleDiff },
            },
            [
              _vm.showDiff
                ? _c("span", [
                    _c("i", { staticClass: "fa-solid fa-eye" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Show current revision")) +
                        "\n          "
                    ),
                  ])
                : _c("span", [
                    _c("i", { staticClass: "fa-solid fa-code-compare" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("Show changes")) + "\n          "
                    ),
                  ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    !_vm.loading
      ? _c(
          "div",
          [
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(_vm._s(_vm.$t("Persistent ID"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-9" }, [
                _vm._v(_vm._s(_vm.revision.id)),
              ]),
            ]),
            _vm._v(" "),
            _vm.revision._links.view_object
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _vm._v(_vm._s(_vm.$t("Object ID"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.revision._links.view_object } },
                      [_c("strong", [_vm._v(_vm._s(_vm.revision.object_id))])]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(_vm._s(_vm.$t("User"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _vm.revision.user
                    ? _c("identity-popover", {
                        attrs: { user: _vm.revision.user },
                      })
                    : _c("em", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("No user."))),
                      ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(_vm._s(_vm.$t("Timestamp"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c("local-timestamp", {
                    attrs: { timestamp: _vm.revision.timestamp },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "small",
                    { staticClass: "text-muted" },
                    [
                      _vm._v("\n          ("),
                      _c("from-now", {
                        attrs: { timestamp: _vm.revision.timestamp },
                      }),
                      _vm._v(")\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.showDiff
              ? _c("div", [
                  _c("div", { staticClass: "card bg-light mb-3" }, [
                    _c("div", { staticClass: "card-body py-2" }, [
                      _c("i", { staticClass: "fa-solid fa-circle-info" }),
                      _vm._v(" "),
                      _c("small", [
                        _vm.compareLatest_
                          ? _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("Comparing to current state"))
                              ),
                            ])
                          : _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("Comparing to previous revision"))
                              ),
                            ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showDiff && !_vm.hasDiff()
              ? _c("em", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("No changes."))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.revision.data, function (value, prop) {
              return _c("div", { key: prop }, [
                !_vm.showDiff || _vm.hasDiff(prop)
                  ? _c("div", [
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.revisionProp(prop))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-9" },
                          [
                            !_vm.showDiff
                              ? _c("clipboard-button", {
                                  staticClass:
                                    "btn-sm float-right py-0 m-1 ml-2",
                                  attrs: {
                                    content: _vm.revisionClipboardValue(value),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "bg-light rounded p-2" }, [
                              _vm.showDiff
                                ? _c(
                                    "pre",
                                    { staticClass: "mb-0 py-2" },
                                    _vm._l(
                                      _vm.getDiff(prop),
                                      function (part, partIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: partIndex,
                                            class: {
                                              "font-italic":
                                                part.value === null,
                                            },
                                          },
                                          [
                                            part.added
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mb-0 diff-add",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.revisionValue(
                                                          part.value
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : part.removed
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mb-0 diff-delete",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.revisionValue(
                                                          part.value
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.revisionValue(
                                                          part.value
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c("div", [
                                    _c(
                                      "pre",
                                      {
                                        staticClass: "mb-0 py-2",
                                        class: {
                                          "font-italic": value === null,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.revisionValue(value)))]
                                    ),
                                  ]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loading
      ? _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }