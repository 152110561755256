var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", attrs: { id: _vm.containerId } }, [
    _c("div", { ref: "toolbar", staticClass: "card toolbar" }, [
      _c(
        "div",
        { staticClass: "card-body px-1 py-0" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-link text-primary my-1",
              class: _vm.previewActive ? " border-active" : "",
              attrs: {
                type: "button",
                title: `${_vm.$t("Preview")} (${_vm.$t("Ctrl")}+P)`,
              },
              on: {
                click: function ($event) {
                  _vm.previewActive = !_vm.previewActive
                },
              },
            },
            [_c("strong", [_vm._v(_vm._s(_vm.$t("Preview")))])]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "separator d-none d-lg-inline" }),
          _vm._v(" "),
          _vm._l(_vm.toolbar, function (tool) {
            return _c("span", { key: tool.label }, [
              tool === "|"
                ? _c("span", { staticClass: "separator d-none d-lg-inline" })
                : _c(
                    "button",
                    {
                      class: _vm.toolbarBtnClasses,
                      attrs: {
                        type: "button",
                        title: _vm.getToolTitle(tool),
                        disabled: _vm.previewActive,
                      },
                      on: { click: tool.handler },
                    },
                    [_c("i", { staticClass: "fa-solid", class: tool.icon })]
                  ),
            ])
          }),
          _vm._v(" "),
          _c("span", { staticClass: "separator d-none d-lg-inline" }),
          _vm._v(" "),
          _c(
            "button",
            {
              class:
                _vm.toolbarBtnClasses +
                (_vm.linkSelectionActive ? " border-active" : ""),
              attrs: {
                type: "button",
                title: _vm.$t("Link"),
                disabled: _vm.previewActive,
              },
              on: {
                click: function ($event) {
                  return _vm.insertLink(true)
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-link" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class:
                _vm.toolbarBtnClasses +
                (_vm.imageSelectionActive ? " border-active" : ""),
              attrs: {
                type: "button",
                title: _vm.$t("Image"),
                disabled: _vm.previewActive,
              },
              on: { click: _vm.insertImage },
            },
            [_c("i", { staticClass: "fa-solid fa-image" })]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "separator d-none d-lg-inline" }),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.toolbarBtnClasses,
              attrs: { type: "button", title: _vm.$t("Toggle fullscreen") },
              on: { click: _vm.toggleFullscreen },
            },
            [_c("i", { staticClass: "fa-solid fa-expand" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.toolbarBtnClasses,
              attrs: {
                type: "button",
                title: `${_vm.$t("Undo")} (${_vm.$t("Ctrl")}+Z)`,
                disabled: !_vm.undoable,
              },
              on: { click: _vm.undo },
            },
            [_c("i", { staticClass: "fa-solid fa-rotate-left" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.toolbarBtnClasses,
              attrs: {
                type: "button",
                title: `${_vm.$t("Redo")} (${_vm.$t("Ctrl")}+Y)`,
                disabled: !_vm.redoable,
              },
              on: { click: _vm.redo },
            },
            [_c("i", { staticClass: "fa-solid fa-rotate-right" })]
          ),
          _vm._v(" "),
          _vm.linkSelectionActive
            ? _c("div", { key: "link", staticClass: "mb-2" }, [
                _c("hr", { staticClass: "mt-0 mb-2" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-block btn-light",
                        attrs: { type: "button", disabled: _vm.previewActive },
                        on: {
                          click: function ($event) {
                            return _vm.insertLink(false)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("Insert link placeholder")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("dynamic-selection", {
                        attrs: {
                          "container-classes": "select2-single-sm",
                          disabled: _vm.previewActive,
                          placeholder: _vm.$t("Select a record file to link"),
                          endpoint: _vm.linkEndpoint,
                          "reset-on-select": true,
                          "dropdown-parent": `#${_vm.containerId}`,
                        },
                        on: { select: _vm.selectLink },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.imageSelectionActive
            ? _c("div", { key: "image", staticClass: "mb-2" }, [
                _c("hr", { staticClass: "mt-0 mb-2" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "input-group input-group-sm" },
                          [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { placeholder: _vm.$t("Width") },
                              domProps: { value: _vm.imageWidth || "" },
                              on: {
                                change: function ($event) {
                                  return _vm.updateImageSize(
                                    "imageWidth",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(0),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "input-group input-group-sm" },
                          [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { placeholder: _vm.$t("Height") },
                              domProps: { value: _vm.imageHeight || "" },
                              on: {
                                change: function ($event) {
                                  return _vm.updateImageSize(
                                    "imageHeight",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(1),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("dynamic-selection", {
                        attrs: {
                          "container-classes": "select2-single-sm",
                          disabled: _vm.previewActive,
                          placeholder: _vm.$t(
                            "Select an uploaded JPEG or PNG image"
                          ),
                          endpoint: _vm.imageEndpoint,
                          "reset-on-select": true,
                          "dropdown-parent": `#${_vm.containerId}`,
                        },
                        on: { select: _vm.selectImage },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.previewActive,
            expression: "!previewActive",
          },
        ],
      },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          ref: "editor",
          staticClass: "form-control editor",
          class: { "has-error": _vm.hasError, "non-resizable": !_vm.resizable },
          attrs: {
            id: _vm.id,
            spellcheck: "false",
            name: _vm.name,
            required: _vm.required,
            rows: _vm.rows,
          },
          domProps: { value: _vm.input },
          on: {
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                return _vm.handleTab.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                $event.preventDefault()
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleEnter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            ],
            input: function ($event) {
              if ($event.target.composing) return
              _vm.input = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "card bg-light footer" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "This editor supports Markdown, including math written in LaTeX syntax rendered with"
                  )
                ) +
                "\n        "
            ),
            _vm._m(2),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "Note that HTML tags and external images are not supported."
                  )
                ) +
                "\n      "
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.previewActive,
            expression: "previewActive",
          },
        ],
      },
      [
        _c(
          "div",
          {
            ref: "preview",
            staticClass: "card preview-container",
            attrs: { tabindex: "-1" },
          },
          [
            _c(
              "div",
              { staticClass: "card-body preview-content" },
              [_c("markdown-renderer", { attrs: { input: _vm.input } })],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("px")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("px")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "text-muted ml-1",
        attrs: {
          href: "https://katex.org/docs/supported.html",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      [
        _c("i", { staticClass: "fa-solid fa-arrow-up-right-from-square" }),
        _vm._v(" "),
        _c("strong", [_vm._v("KaTeX")]),
        _vm._v(".\n        "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }