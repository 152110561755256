var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("extras-editor", {
        attrs: {
          id: _vm.field.id,
          name: _vm.field.name,
          label: _vm.field.label,
          "initial-values": _vm.field.data,
          "edit-extra-keys": _vm.editExtraKeys,
          "template-endpoint": _vm.templateEndpoint,
          "terms-endpoint": _vm.termsEndpoint,
          "editing-mode": _vm.editingMode_,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }