var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: { href: "#", tabindex: "-1" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.collapseItem.apply(null, arguments)
        },
      },
    },
    [
      _c("i", { class: _vm.iconClass }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.collapseText))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }