var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.input
    ? _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn clear-btn",
            attrs: { type: "button" },
            on: { click: _vm.clearInput },
          },
          [_c("i", { staticClass: "fa-solid fa-xmark" })]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }