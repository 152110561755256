var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dynamic-pagination", {
    ref: "pagination",
    attrs: { endpoint: _vm.endpoint, placeholder: _vm.$t("No revisions.") },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _vm.title
                ? _c("p", [
                    _c("strong", [_vm._v(_vm._s(_vm.title))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "badge-total" }, [
                      _vm._v(_vm._s(props.total)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(props.items, function (revision, index) {
                return _c(
                  "div",
                  {
                    key: revision.id,
                    class: { "mb-3": index < props.items.length - 1 },
                  },
                  [
                    _c("div", { staticClass: "form-row align-items-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-1 d-md-flex justify-content-center d-none d-md-block",
                        },
                        [
                          _c("span", { staticClass: "fa-stack" }, [
                            _c("i", {
                              staticClass: "fa-solid fa-circle fa-stack-2x",
                              class: _vm.getRevisionType(revision).color,
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa-solid fa-stack-1x text-white",
                              class: _vm.getRevisionType(revision).icon,
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          revision.user
                            ? _c(
                                "span",
                                [
                                  _c("identity-popover", {
                                    attrs: { user: revision.user },
                                  }),
                                  _vm._v(" "),
                                  _c("em", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getRevisionType(revision).textUser
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t(
                            "default",
                            function () {
                              return [
                                _c("strong", [
                                  _vm._v(_vm._s(revision.data.title)),
                                ]),
                              ]
                            },
                            { revision: revision }
                          ),
                          _vm._v(" "),
                          !revision.user
                            ? _c("span", [
                                _c("em", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getRevisionType(revision).textNoUser
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("a", { attrs: { href: revision._links.view } }, [
                            _c("i", { staticClass: "fa-solid fa-eye" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("View revision")) +
                                "\n          "
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-md-right" }, [
                        _c(
                          "small",
                          { staticClass: "text-muted" },
                          [
                            _c("from-now", {
                              attrs: { timestamp: revision.timestamp },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }