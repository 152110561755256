var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "input-group input-group-sm mb-2" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.filter,
              expression: "filter",
              modifiers: { trim: true },
            },
          ],
          staticClass: "form-control",
          attrs: { id: _vm.filterId, placeholder: _vm.$t("Filter rows") },
          domProps: { value: _vm.filter },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.filter = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _vm._v(" "),
        _c("clear-button", {
          attrs: { "input-id": _vm.filterId, input: _vm.filter },
          on: {
            "clear-input": function ($event) {
              _vm.filter = ""
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.encoding
      ? _c("small", { staticClass: "text-muted" }, [
          _vm._v(
            _vm._s(_vm.$t("Detected encoding")) +
              ": " +
              _vm._s(_vm.encoding.toUpperCase())
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive max-vh-75 mt-2" }, [
      _c(
        "table",
        { staticClass: "table table-sm table-bordered table-hover" },
        [
          _vm.hasHeader
            ? _c("thead", { staticClass: "bg-light" }, [
                _c(
                  "tr",
                  _vm._l(_vm.headerRow, function (value, index) {
                    return _c("th", { key: index }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "cursor-pointer d-flex justify-content-between",
                          on: {
                            click: function ($event) {
                              return _vm.sortRow(index)
                            },
                          },
                        },
                        [
                          _c("strong", [
                            _c("pre", { staticClass: "d-inline" }, [
                              _vm._v(_vm._s(value)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.sort.index === index
                            ? _c("span", { staticClass: "ml-2" }, [
                                _vm.sort.direction === "desc"
                                  ? _c("i", {
                                      staticClass: "fa-solid fa-angle-up",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.sort.direction === "asc"
                                  ? _c("i", {
                                      staticClass: "fa-solid fa-angle-down",
                                    })
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.displayedDataRows, function (row, rowIndex) {
              return _c(
                "tr",
                { key: rowIndex },
                _vm._l(row, function (value, valueIndex) {
                  return _c("td", { key: valueIndex }, [
                    _c("pre", { staticClass: "mb-0" }, [_vm._v(_vm._s(value))]),
                  ])
                }),
                0
              )
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }