<!-- Copyright 2024 Karlsruhe Institute of Technology
   -
   - Licensed under the Apache License, Version 2.0 (the "License");
   - you may not use this file except in compliance with the License.
   - You may obtain a copy of the License at
   -
   -     http://www.apache.org/licenses/LICENSE-2.0
   -
   - Unless required by applicable law or agreed to in writing, software
   - distributed under the License is distributed on an "AS IS" BASIS,
   - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   - See the License for the specific language governing permissions and
   - limitations under the License. -->

<template>
  <div ref="dialog" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-light py-2">
          <strong class="modal-title">{{ title }}</strong>
          <button type="button" class="close" data-dismiss="modal">
            <i class="fa-solid fa-xmark fa-xs"></i>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div v-if="showFooter" class="modal-footer d-block">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      showFooter: false,
    };
  },
  mounted() {
    $(this.$refs.dialog).on('shown.bs.modal', () => {
      // Ensure the backdrop is always visible.
      const backdrop = document.getElementsByClassName('modal-backdrop')[0];
      this.$el.parentNode.insertBefore(backdrop, this.$el.nextSibling);
    });

    this.showFooter = Boolean(this.$slots.footer);
  },
  beforeDestroy() {
    $(this.$refs.dialog).modal('dispose');
  },
  methods: {
    open() {
      $(this.$refs.dialog).modal();
    },
    hide() {
      $(this.$refs.dialog).modal('hide');
    },
  },
};
</script>
