var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { staticClass: "form-control-label" }, [
        _vm._v(_vm._s(_vm.field.label)),
      ]),
      _vm._v(" "),
      _c("resource-export-filter", {
        attrs: {
          "resource-type": _vm.resourceType,
          "export-type": _vm.exportType,
          extras: _vm.extras,
          "allow-extras-propagation": _vm.allowExtrasPropagation,
          "default-user-filter": _vm.defaultUserFilter,
        },
        on: {
          filter: function ($event) {
            _vm.filter = $event
          },
        },
      }),
      _vm._v(" "),
      _c("small", { staticClass: "form-text text-muted" }, [
        _vm._v(_vm._s(_vm.field.description)),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field.name },
        domProps: { value: JSON.stringify(_vm.filter) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }