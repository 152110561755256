var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.scope === null
      ? _c("em", [_vm._v(_vm._s(_vm.$t("Full access")))])
      : _c(
          "div",
          {
            on: {
              pointerover: function ($event) {
                _vm.showAllScopes = true
              },
              pointerleave: function ($event) {
                _vm.showAllScopes = false
              },
            },
          },
          [
            _vm.showAllScopes
              ? _c("div", [
                  _c("pre", { staticClass: "ws-pre-wrap mb-0" }, [
                    _vm._v(_vm._s(_vm.allScopes)),
                  ]),
                ])
              : _c("div", [
                  _c("pre", { staticClass: "ws-pre-wrap mb-0" }, [
                    _vm._v(_vm._s(_vm.firstShownScopes)),
                  ]),
                  _vm._v(" "),
                  _c("em", [_vm._v(_vm._s(_vm.scopesHoverText))]),
                ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }