var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-light text-muted",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.showMarkdown = !_vm.showMarkdown
              },
            },
          },
          [
            _vm.showMarkdown
              ? _c("span", [
                  _c("i", { staticClass: "fa-solid fa-code" }),
                  _vm._v(" " + _vm._s(_vm.$t("Plain text")) + "\n        "),
                ])
              : _c("span", [
                  _c("i", { staticClass: "fa-solid fa-eye" }),
                  _vm._v(" " + _vm._s(_vm.$t("Markdown")) + "\n        "),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.encoding
        ? _c(
            "div",
            {
              staticClass:
                "col-lg-6 d-lg-flex justify-content-end align-items-end",
            },
            [
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  _vm._s(_vm.$t("Detected encoding")) +
                    ": " +
                    _vm._s(_vm.encoding.toUpperCase())
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card bg-light",
        class: { "max-vh-75 overflow-auto": _vm.showMarkdown },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMarkdown,
                expression: "showMarkdown",
              },
            ],
            staticClass: "card-body",
          },
          [_c("markdown-renderer", { attrs: { input: _vm.lines.join("\n") } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showMarkdown,
                expression: "!showMarkdown",
              },
            ],
          },
          [
            _c("text-viewer", {
              attrs: { lines: _vm.lines, "show-border": false },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }