var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "input",
    staticClass: "form-control time-picker-input",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }