var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dynamic-pagination", {
    attrs: {
      endpoint: _vm.endpoint,
      args: { sort: _vm.sort },
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (paginationProps) {
          return [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-md-6 col-xl-8" }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.title))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge-total" }, [
                    _vm._v(_vm._s(paginationProps.total)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              paginationProps.totalUnfiltered > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-md-6 col-xl-4 mb-3 mb-md-2 d-flex justify-content-end",
                    },
                    [
                      _vm.enableSort && paginationProps.total > 1
                        ? _c("div", { staticClass: "flex-grow-1 mr-2" }, [
                            _c(
                              "div",
                              { staticClass: "input-group input-group-sm" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-group-text",
                                        attrs: { for: `sort-${_vm.id}` },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Sort by")))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sort,
                                        expression: "sort",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    attrs: { id: `sort-${_vm.id}` },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.sort = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  _vm._l(_vm.sortOptions, function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option[0],
                                        domProps: { value: option[0] },
                                      },
                                      [_vm._v(_vm._s(option[1]))]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      paginationProps.total > 0
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-light",
                                attrs: { title: _vm.$t("Change layout") },
                                on: { click: _vm.changeView },
                              },
                              [
                                _vm.listView
                                  ? _c("i", {
                                      staticClass: "fa-solid fa-table",
                                    })
                                  : _c("i", {
                                      staticClass: "fa-solid fa-list-ul",
                                    }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.listView
              ? _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(paginationProps.items, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass:
                          "list-group-item list-group-item-action py-1",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "text-default stretched-link",
                            attrs: { href: item._links.view },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-row align-items-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "trim-ws", rawName: "v-trim-ws" },
                                    ],
                                    staticClass: "col-md-4 order-1",
                                  },
                                  [
                                    _c(
                                      "small",
                                      [
                                        _c("resource-visibility", {
                                          attrs: {
                                            visibility: item.visibility,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("strong", { staticClass: "ml-2" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-3 order-3" }, [
                                  _c("small", [
                                    _vm._v("@" + _vm._s(item.identifier)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-3 order-3" }, [
                                  _c(
                                    "small",
                                    { staticClass: "text-muted" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("Last modified")) +
                                          " "
                                      ),
                                      _c("from-now", {
                                        attrs: {
                                          timestamp: item.last_modified,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-2 d-md-flex justify-content-end order-2 order-md-4",
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("resource-type", {
                                          attrs: {
                                            type: item.type,
                                            "is-template": Boolean(item.data),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("card-deck", {
                  attrs: { items: paginationProps.items },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("div", { staticClass: "card-body py-2" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "text-default stretched-link",
                                  attrs: { href: props.item._links.view },
                                },
                                [
                                  _c("resource-type", {
                                    staticClass:
                                      "float-right badge-mt-plus ml-3",
                                    attrs: {
                                      type: props.item.type,
                                      "is-template": Boolean(props.item.data),
                                    },
                                  }),
                                  _vm._v(" "),
                                  props.item._links.image
                                    ? _c("img", {
                                        staticClass:
                                          "img-max-75 img-thumbnail float-right ml-3",
                                        attrs: {
                                          loading: "lazy",
                                          src: props.item._links.image,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("basic-resource-info", {
                                    attrs: {
                                      resource: props.item,
                                      "show-description": _vm.showDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-footer py-1" }, [
                              _c(
                                "small",
                                { staticClass: "text-muted" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Last modified")) +
                                      " "
                                  ),
                                  _c("from-now", {
                                    attrs: {
                                      timestamp: props.item.last_modified,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }