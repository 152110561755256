<!-- Copyright 2022 Karlsruhe Institute of Technology
   -
   - Licensed under the Apache License, Version 2.0 (the "License");
   - you may not use this file except in compliance with the License.
   - You may obtain a copy of the License at
   -
   -     http://www.apache.org/licenses/LICENSE-2.0
   -
   - Unless required by applicable law or agreed to in writing, software
   - distributed under the License is distributed on an "AS IS" BASIS,
   - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   - See the License for the specific language governing permissions and
   - limitations under the License. -->

<template>
  <tooltip-item class="elevated" :title="title">
    <i class="fa-solid d-inline" :class="icon"></i>
  </tooltip-item>
</template>

<script>
export default {
  props: {
    visibility: String,
  },
  computed: {
    title() {
      const visibility = this.visibility === 'public' ? $t('Public') : $t('Private');
      return `${$t('Visibility')}: ${visibility}`;
    },
    icon() {
      return this.visibility === 'public' ? 'fa-lock-open' : 'fa-lock';
    },
  },
};
</script>
