var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tooltip-item",
    { staticClass: "elevated", attrs: { title: _vm.title } },
    [_c("i", { staticClass: "fa-solid d-inline", class: _vm.icon })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }