var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { directives: [{ name: "trim-ws", rawName: "v-trim-ws" }] },
    [
      _c("popover-toggle", {
        attrs: {
          "toggle-class": "",
          placement: "top",
          trigger: "hover",
          width: 300,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "toggle",
              fn: function () {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.user._links.view,
                        target: _vm.openInNewTab ? "_blank" : false,
                        rel: _vm.openInNewTab ? "noopener noreferrer" : false,
                      },
                    },
                    [
                      _vm._t(
                        "default",
                        function () {
                          return [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.kadi.utils.truncate(
                                    _vm.user.displayname,
                                    50
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                        { user: _vm.user }
                      ),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("span", { staticClass: "row" }, [
                    _vm.user._links.image
                      ? _c("span", { staticClass: "col-4" }, [
                          _c("img", {
                            staticClass: "img-max-75 img-thumbnail",
                            attrs: { src: _vm.user._links.image },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: {
                          "col-8": _vm.user._links.image,
                          "col-12": !_vm.user._links.image,
                        },
                      },
                      [
                        _c("strong", { staticClass: "text-break" }, [
                          _vm._v(_vm._s(_vm.user.displayname)),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-break" }, [
                          _vm._v("@" + _vm._s(_vm.user.identity.username)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Account type")) +
                              ": " +
                              _vm._s(_vm.user.identity.name)
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }