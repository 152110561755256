var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("confirm-dialog", { ref: "dialog" }),
      _vm._v(" "),
      _c("dynamic-pagination", {
        ref: "pagination",
        attrs: {
          endpoint: _vm.endpoint,
          placeholder: _vm.placeholder,
          "per-page": _vm.perPage,
          "enable-filter": _vm.enableFilter,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.title))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge-total" }, [
                    _vm._v(_vm._s(props.total)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(props.items, function (resource) {
                    return _c(
                      "li",
                      { key: resource.id, staticClass: "list-group-item py-1" },
                      [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-md-10 mb-2 mb-md-0" }, [
                            _c(
                              "a",
                              { attrs: { href: resource._links.view } },
                              [
                                _c("basic-resource-info", {
                                  attrs: {
                                    resource: resource,
                                    "show-description": false,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-2 mb-2 mb-lg-0 d-md-flex justify-content-end",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-danger",
                                  attrs: {
                                    type: "button",
                                    title: _vm.$t("Remove link"),
                                    disabled: resource.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeLink(resource)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa-solid fa-trash" })]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }