var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.nestedType
      ? _c(
          "div",
          {
            staticClass: "row align-items-center",
            class: { "mb-2": _vm.showToolbar },
          },
          [
            _c(
              "div",
              { staticClass: "col-sm-6 mb-2 mb-sm-0" },
              [_vm._t("default")],
              2
            ),
            _vm._v(" "),
            _vm.showToolbar
              ? _c(
                  "div",
                  { staticClass: "col-sm-6 d-sm-flex justify-content-end" },
                  [
                    _c(
                      "div",
                      { staticClass: "btn-group btn-group-sm" },
                      [
                        _vm.hasNestedType
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: {
                                  type: "button",
                                  title: _vm.$t("Collapse all"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.collapseExtras(true)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa-regular fa-lg fa-square-minus",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasNestedType
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: {
                                  type: "button",
                                  title: _vm.$t("Expand all"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.collapseExtras(false)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa-regular fa-lg fa-square-plus",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("clipboard-button", {
                          staticClass: "btn btn-light",
                          attrs: {
                            content: _vm.serializedExtras,
                            "show-tooltip": false,
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasNullValues
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.hideNullValues_ = !_vm.hideNullValues_
                                  },
                                },
                              },
                              [
                                _vm.hideNullValues_
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa-solid fa-eye",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Show null values")) +
                                          "\n          "
                                      ),
                                    ])
                                  : _c("span", [
                                      _c("i", {
                                        staticClass: "fa-solid fa-eye-slash",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Hide null values")) +
                                          "\n          "
                                      ),
                                    ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list-group", class: { "mb-2": _vm.depth > 0 } },
      _vm._l(_vm.filteredExtras, function (extra, index) {
        return _c(
          "li",
          {
            key: extra.id,
            staticClass: "list-group-item extra py-1 pl-3 pr-0",
            class: { odd: _vm.depth % 2 == 1, nested: _vm.depth > 0 },
          },
          [
            _c(
              "div",
              {
                staticClass: "row align-items-center",
                class: {
                  "mb-1":
                    _vm.kadi.utils.isNestedType(extra.type) &&
                    extra.value.length > 0 &&
                    !extra.isCollapsed,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    !_vm.kadi.utils.isNestedType(extra.type)
                      ? _c("span", [
                          _vm._v(_vm._s(extra.key || `(${index + 1})`)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kadi.utils.isNestedType(extra.type)
                      ? _c(
                          "collapse-item",
                          {
                            attrs: {
                              id: extra.id,
                              "show-icon-class": "",
                              "hide-icon-class": "",
                              "is-collapsed": extra.isCollapsed,
                            },
                            on: {
                              collapse: function ($event) {
                                extra.isCollapsed = $event
                              },
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(extra.key || `(${index + 1})`)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-5" },
                  [
                    !_vm.kadi.utils.isNestedType(extra.type)
                      ? _c("div", [
                          extra.value === null
                            ? _c("span", [_c("em", [_vm._v("null")])])
                            : _c(
                                "span",
                                [
                                  extra.type === "date"
                                    ? _c("local-timestamp", {
                                        attrs: { timestamp: extra.value },
                                      })
                                    : extra.validation &&
                                      extra.validation.iri &&
                                      _vm.kadi.utils.isHttpUrl(extra.value)
                                    ? _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "trim-ws",
                                              rawName: "v-trim-ws",
                                            },
                                          ],
                                          staticClass: "text-primary",
                                          attrs: {
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                            href: extra.value,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-arrow-up-right-from-square mr-1",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(extra.value)),
                                          ]),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.getExtraValue(extra))
                                        ),
                                      ]),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(extra.unit)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kadi.utils.isNestedType(extra.type) &&
                    extra.isCollapsed &&
                    extra.value.length > 0
                      ? _c(
                          "collapse-item",
                          {
                            attrs: {
                              id: extra.id,
                              "show-icon-class": "",
                              "hide-icon-class": "",
                              "is-collapsed": extra.isCollapsed,
                            },
                            on: {
                              collapse: function ($event) {
                                extra.isCollapsed = $event
                              },
                            },
                          },
                          [_c("strong", [_vm._v("[...]")])]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2 d-md-flex justify-content-end" },
                  [
                    _c("small", { staticClass: "text-muted mr-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.kadi.utils.capitalize(
                            _vm.kadi.utils.prettyTypeName(extra.type)
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-1 d-md-flex justify-content-end" },
                  [
                    extra.description || extra.term || extra.validation
                      ? _c(
                          "button",
                          {
                            staticClass: "float-md-right mr-3 mr-md-0",
                            class: _vm.toggleClasses,
                            attrs: {
                              type: "button",
                              title: _vm.$t("Toggle additional information"),
                            },
                            on: {
                              click: function ($event) {
                                extra.showDetails = !extra.showDetails
                              },
                            },
                          },
                          [
                            extra.showDetails
                              ? _c("i", { staticClass: "fa-solid fa-angle-up" })
                              : _c("i", {
                                  staticClass: "fa-solid fa-angle-down",
                                }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-md-none" }, [
                              _vm._v(
                                _vm._s(_vm.$t("Toggle additional information"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editEndpoint
                      ? _c(
                          "a",
                          {
                            class: _vm.toggleClasses,
                            attrs: {
                              title: _vm.$t("Edit extra"),
                              href: _vm.getEditLink(extra, index),
                            },
                          },
                          [
                            _c("i", { staticClass: "fa-solid fa-pencil" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-md-none" }, [
                              _vm._v(_vm._s(_vm.$t("Edit extra"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            extra.showDetails
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card card-body bg-light py-1 px-2 my-1 mr-3",
                    },
                    [
                      extra.description
                        ? _c("div", [
                            _c("div", { staticClass: "row my-2 my-sm-0" }, [
                              _c("small", { staticClass: "col-sm-4" }, [
                                _c("em", [
                                  _vm._v(_vm._s(_vm.$t("Description"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "col-sm-8" }, [
                                _c("span", { staticClass: "ws-pre-wrap" }, [
                                  _vm._v(_vm._s(extra.description)),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      extra.term
                        ? _c("div", [
                            extra.description
                              ? _c("hr", { staticClass: "my-1" })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row my-2 my-sm-0" }, [
                              _c("small", { staticClass: "col-sm-4" }, [
                                _c("em", [_vm._v(_vm._s(_vm.$t("Term IRI")))]),
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "col-sm-8" }, [
                                _vm.kadi.utils.isHttpUrl(extra.term)
                                  ? _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "trim-ws",
                                            rawName: "v-trim-ws",
                                          },
                                        ],
                                        staticClass: "text-primary",
                                        attrs: {
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                          href: extra.term,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa-solid fa-arrow-up-right-from-square mr-1",
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(extra.term)),
                                        ]),
                                      ]
                                    )
                                  : _c("span", [_vm._v(_vm._s(extra.term))]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      extra.validation
                        ? _c(
                            "div",
                            [
                              extra.description || extra.term
                                ? _c("hr", { staticClass: "my-1" })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(extra.validation, function (value, key) {
                                return _c(
                                  "div",
                                  { key: key, staticClass: "row my-2 my-sm-0" },
                                  [
                                    _c("small", { staticClass: "col-sm-4" }, [
                                      _c("em", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Validation")) +
                                            " (" +
                                            _vm._s(
                                              _vm.validationKeys[key] || key
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "col-sm-8" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getValidationValue(
                                            extra,
                                            key,
                                            value
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.kadi.utils.isNestedType(extra.type) && extra.value.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    { attrs: { id: extra.id } },
                    [
                      _c("extras-viewer", {
                        attrs: {
                          extras: extra.value,
                          "edit-endpoint": _vm.editEndpoint,
                          "nested-type": extra.type,
                          "nested-keys": [
                            ..._vm.nestedKeys,
                            extra.key || index,
                          ],
                          "hide-null-values": _vm.hideNullValues_,
                          depth: _vm.depth + 1,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }