var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "form-control-label" }, [
      _vm._v(_vm._s(_vm.field.label)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body px-3 pt-3 pb-2" }, [
        _c(
          "div",
          { staticClass: "d-lg-flex justify-content-between" },
          _vm._l(_vm.scopes, function (actions, object, index) {
            return _c(
              "div",
              { key: object, class: { "mt-4 mt-lg-0": index > 0 } },
              [
                _c("input", {
                  attrs: { id: object, type: "checkbox" },
                  domProps: { checked: _vm.objectScopesChecked(object) },
                  on: {
                    click: function ($event) {
                      return _vm.checkObjectScopes(object, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: object } }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.kadi.utils.capitalize(object))),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(actions, function (action) {
                  return _c("div", { key: _vm.getScopeValue(object, action) }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scopesModel[object][action].checked,
                          expression: "scopesModel[object][action].checked",
                        },
                      ],
                      attrs: {
                        id: _vm.getScopeValue(object, action),
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.scopesModel[object][action].checked
                        )
                          ? _vm._i(
                              _vm.scopesModel[object][action].checked,
                              null
                            ) > -1
                          : _vm.scopesModel[object][action].checked,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.scopesModel[object][action].checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.scopesModel[object][action],
                                  "checked",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.scopesModel[object][action],
                                  "checked",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.scopesModel[object][action],
                              "checked",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: _vm.getScopeValue(object, action) } },
                      [_vm._v(_vm._s(action))]
                    ),
                  ])
                }),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("small", { staticClass: "form-text text-muted" }, [
      _vm._v(_vm._s(_vm.field.description)),
    ]),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: _vm.field.name },
      domProps: { value: _vm.serializedScopes },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }