var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "form-control-label" }, [
      _vm._v(_vm._s(_vm.field.label)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body p-2" },
        [
          _c(
            "vue-draggable",
            {
              attrs: {
                handle: ".sort-handle",
                list: _vm.currentLayout,
                "force-fallback": true,
              },
            },
            _vm._l(_vm.currentLayout, function (resourceConfig, index) {
              return _c(
                "div",
                {
                  key: resourceConfig.resource,
                  staticClass: "border rounded bg-light p-2",
                  class: { "mb-2": index < _vm.currentLayout.length - 1 },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-sm-6 mb-2 mb-sm-0" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-sm btn-light disabled sort-handle",
                          attrs: { type: "button" },
                        },
                        [_c("i", { staticClass: "fa-solid fa-bars" })]
                      ),
                      _vm._v(" "),
                      _c("strong", { staticClass: "ml-1" }, [
                        _vm._v(
                          _vm._s(_vm.resourceTypes[resourceConfig.resource])
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 d-sm-flex justify-content-end" },
                      [
                        _c(
                          "small",
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("Maximum amount of resources")) +
                                ": " +
                                _vm._s(resourceConfig.max_items) +
                                "\n                "
                            ),
                            _c("range-slider", {
                              attrs: {
                                max: 10,
                                step: 2,
                                "initial-value": resourceConfig.max_items,
                              },
                              on: {
                                input: function ($event) {
                                  resourceConfig.max_items = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "mt-1 mb-2" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                      _c("div", { staticClass: "input-group input-group-sm" }, [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v(_vm._s(_vm.$t("Creator"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: resourceConfig.creator,
                                expression: "resourceConfig.creator",
                              },
                            ],
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  resourceConfig,
                                  "creator",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.creatorTypes, function (creator) {
                            return _c(
                              "option",
                              {
                                key: creator.key,
                                domProps: { value: creator.key },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(creator.title) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                      _c("div", { staticClass: "input-group input-group-sm" }, [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v(_vm._s(_vm.$t("Visibility"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: resourceConfig.visibility,
                                expression: "resourceConfig.visibility",
                              },
                            ],
                            staticClass: "custom-select",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  resourceConfig,
                                  "visibility",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.visibilityTypes, function (visibility) {
                            return _c(
                              "option",
                              {
                                key: visibility.key,
                                domProps: { value: visibility.key },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(visibility.title) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-4 d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: resourceConfig.explicit_permissions,
                                expression:
                                  "resourceConfig.explicit_permissions",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: `permissions-${index}-${_vm.suffix}`,
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                resourceConfig.explicit_permissions
                              )
                                ? _vm._i(
                                    resourceConfig.explicit_permissions,
                                    null
                                  ) > -1
                                : resourceConfig.explicit_permissions,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = resourceConfig.explicit_permissions,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        resourceConfig,
                                        "explicit_permissions",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        resourceConfig,
                                        "explicit_permissions",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    resourceConfig,
                                    "explicit_permissions",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: {
                                for: `permissions-${index}-${_vm.suffix}`,
                              },
                            },
                            [
                              resourceConfig.resource === "group"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "Only consider groups with membership"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Only consider explicit permissions"
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("small", { staticClass: "form-text text-muted" }, [
      _vm._v(_vm._s(_vm.field.description)),
    ]),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: _vm.field.name },
      domProps: { value: _vm.serializedLayout },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }