var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-field", {
    ref: "base",
    attrs: { field: _vm.field },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _vm._t("prepend"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data,
                        expression: "data",
                      },
                    ],
                    class: [{ "has-error": props.hasError }, _vm.classes],
                    attrs: {
                      id: _vm.field.id,
                      name: _vm.field.name,
                      required: _vm.field.validation.required,
                      disabled: _vm.disabled,
                      placeholder: _vm.placeholder,
                    },
                    domProps: { value: _vm.data },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.data = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm._t("append"),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }