var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.encoding && !_vm.isNested
      ? _c("div", { staticClass: "mb-2" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              _vm._s(_vm.$t("Detected encoding")) +
                ": " +
                _vm._s(_vm.encoding.toUpperCase())
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "card bg-light max-vh-75 overflow-auto px-3 py-2": !_vm.isNested,
        },
      },
      [
        _vm.isNestedValue(_vm.data)
          ? _c(
              "div",
              _vm._l(_vm.data, function (item, index_or_key) {
                return _c("div", { key: item.id }, [
                  _vm.isNestedValue(item.value)
                    ? _c(
                        "div",
                        [
                          _c("collapse-item", { attrs: { id: item.id } }, [
                            _c("strong", [
                              _c("pre", { staticClass: "d-inline" }, [
                                _vm._v(_vm._s(index_or_key) + ":"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.kadi.utils.isArray(item.value) &&
                          item.value.length === 0
                            ? _c("pre", { staticClass: "d-inline" }, [
                                _vm._v("[]"),
                              ])
                            : _vm.kadi.utils.isObject(item.value) &&
                              Object.keys(item.value).length === 0
                            ? _c("pre", { staticClass: "d-inline" }, [
                                _vm._v("{}"),
                              ])
                            : _c("json-viewer", {
                                staticClass: "ml-4",
                                attrs: {
                                  id: item.id,
                                  json: item.value,
                                  "is-nested": true,
                                },
                              }),
                        ],
                        1
                      )
                    : _c("div", [
                        _c("strong", [
                          _c("pre", { staticClass: "d-inline" }, [
                            _vm._v(_vm._s(index_or_key) + ":"),
                          ]),
                        ]),
                        _vm._v(" "),
                        item.value === null
                          ? _c("pre", { staticClass: "d-inline text-muted" }, [
                              _vm._v("null"),
                            ])
                          : _c("pre", { staticClass: "d-inline" }, [
                              _vm._v(_vm._s(JSON.stringify(item.value))),
                            ]),
                      ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isNestedValue(_vm.data)
          ? _c("div", [
              _vm.data === null
                ? _c("pre", { staticClass: "d-inline text-muted" }, [
                    _vm._v("null"),
                  ])
                : _c("pre", { staticClass: "d-inline" }, [
                    _vm._v(_vm._s(JSON.stringify(_vm.data))),
                  ]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }