var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { card: _vm.showBorder } },
    [
      _vm.title
        ? _c("div", { staticClass: "card-header py-2" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: { "card-body p-3": _vm.showBorder } }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value",
              },
            ],
            staticClass: "custom-select custom-select-sm",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.value = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.choices, function (choice) {
            return _c(
              "option",
              { key: choice[0], domProps: { value: choice[0] } },
              [_vm._v(_vm._s(choice[1]))]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }