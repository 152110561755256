var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-header py-2" }, [
        _vm._v(_vm._s(_vm.$t("Results per page")) + ": " + _vm._s(_vm.value)),
      ]),
      _vm._v(" "),
      _c("range-slider", {
        staticClass: "mt-3 mx-2 mb-2",
        attrs: { min: _vm.min, "initial-value": _vm.value },
        on: {
          input: function ($event) {
            _vm.value = $event
          },
          change: _vm.change,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }