var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dynamic-pagination", {
    attrs: {
      endpoint: _vm.endpoint,
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (paginationProps) {
            return [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("p", [
                      _c("strong", [_vm._v(_vm._s(_vm.title))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "badge-total" }, [
                        _vm._v(_vm._s(paginationProps.total)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._t("default"),
                ],
                2
              ),
              _vm._v(" "),
              _c("card-deck", {
                attrs: { items: paginationProps.items },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            { staticClass: "card-body py-2" },
                            [
                              _c(
                                _vm.getLink(props.item) ? "a" : "div",
                                {
                                  tag: "a",
                                  class: _vm.getLinkClass(props.item),
                                  attrs: { href: _vm.getLink(props.item) },
                                },
                                [
                                  props.item.user
                                    ? _c("div", [
                                        _vm.hasImage(props.item)
                                          ? _c("img", {
                                              staticClass:
                                                "img-max-75 img-thumbnail float-right ml-3",
                                              attrs: {
                                                loading: "lazy",
                                                src: props.item.user._links
                                                  .image,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "d-flow-root" },
                                          [
                                            _c(
                                              "strong",
                                              {
                                                attrs: {
                                                  title:
                                                    props.item.user.displayname,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.kadi.utils.truncate(
                                                        props.item.user
                                                          .displayname,
                                                        50
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v(
                                            "@" +
                                              _vm._s(
                                                props.item.user.identity
                                                  .username
                                              )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Account type")) +
                                                ": " +
                                                _vm._s(
                                                  props.item.user.identity.name
                                                )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.item.group
                                    ? _c(
                                        "div",
                                        [
                                          _vm.hasImage(props.item)
                                            ? _c("img", {
                                                staticClass:
                                                  "img-max-75 img-thumbnail float-right ml-3",
                                                attrs: {
                                                  loading: "lazy",
                                                  src: props.item.group._links
                                                    .image,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("basic-resource-info", {
                                            attrs: {
                                              resource: props.item.group,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-footer d-flex justify-content-between align-items-center py-1",
                            },
                            [
                              _c("strong", { staticClass: "text-primary" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.kadi.utils.capitalize(
                                      props.item.role.name
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              props.item.user &&
                              _vm.creator === props.item.user.id
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-light border font-weight-normal",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("Creator")) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }