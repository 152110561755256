var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "dropdown" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-light dropdown-toggle mr-1",
            attrs: { type: "button", "data-toggle": "dropdown" },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("Import from file")) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.openInput("json")
                },
              },
            },
            [_vm._v("JSON")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("popover-toggle", {
        attrs: {
          "toggle-class": "btn btn-sm btn-light text-muted",
          title: _vm.$t("Supported formats"),
        },
        scopedSlots: _vm._u([
          {
            key: "toggle",
            fn: function () {
              return [
                _c("i", { staticClass: "fa-regular fa-circle-question" }),
                _vm._v(" " + _vm._s(_vm.$t("Help")) + "\n    "),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c("strong", [_vm._v("JSON")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-10" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "Only the JSON format used by Kadi4Mat is currently supported. Valid contents include the exported data of records, record templates and extras templates, of which the basic and generic record metadata will be used."
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "submission-form",
        {
          ref: "form",
          attrs: { enctype: "multipart/form-data", "check-dirty": false },
        },
        [
          _c("input", {
            staticClass: "input",
            attrs: { name: _vm.importTypeName },
            domProps: { value: _vm.importType },
          }),
          _vm._v(" "),
          _c("input", {
            ref: "input",
            staticClass: "input",
            attrs: {
              type: "file",
              name: _vm.importDataName,
              accept: _vm.accept,
            },
            on: { change: _vm.changeFile },
          }),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }