var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.roles.length > 0
    ? _c("popover-toggle", {
        attrs: { "toggle-class": _vm.toggleClasses, title: _vm.$t("Roles") },
        scopedSlots: _vm._u(
          [
            {
              key: "toggle",
              fn: function () {
                return [
                  _c("i", { staticClass: "fa-solid fa-circle-info" }),
                  _vm._v(" " + _vm._s(_vm.$t("Roles")) + "\n  "),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return _vm._l(_vm.roles, function (role, index) {
                  return _c(
                    "div",
                    { key: role.name },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.kadi.utils.capitalize(role.name))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm._l(role.permissions, function (permission) {
                        return _c(
                          "div",
                          { key: permission.action, staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-3" }, [
                              _vm._v(_vm._s(permission.action)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-9" }, [
                              _c("small", [
                                _vm._v(_vm._s(permission.description)),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      index < _vm.roles.length - 1 ? _c("hr") : _vm._e(),
                    ],
                    2
                  )
                })
              },
              proxy: true,
            },
          ],
          null,
          false,
          11715584
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }