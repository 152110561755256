var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header py-2 d-flex justify-content-between align-items-center",
        },
        [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
          _vm.items.length > 0
            ? _c(
                "button",
                {
                  staticClass: "close text-default",
                  attrs: { type: "button" },
                  on: { click: _vm.clearItems },
                },
                [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body p-3" },
        [
          _vm.items.length > 0
            ? _c("div", { staticClass: "mb-3" }, [
                _c(
                  "h5",
                  { staticClass: "d-inline" },
                  _vm._l(_vm.items, function (item) {
                    return _c(
                      "span",
                      {
                        key: item.id,
                        staticClass:
                          "filter-item badge badge-light border font-weight-normal m-1",
                        attrs: { title: item.text },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.kadi.utils.truncate(item.text, 20)) +
                            "\n          "
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-link text-muted shadow-none p-0 ml-1",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa-solid fa-xmark fa-sm" })]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("dynamic-selection", {
            attrs: {
              "container-classes": "select2-single-sm",
              endpoint: _vm.endpoint,
              placeholder: _vm.placeholder,
              "reset-on-select": true,
            },
            on: { select: _vm.addItem },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }