var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    { attrs: { id: _vm.id, multiple: _vm.multiple, disabled: _vm.disabled } },
    [!_vm.multiple ? _c("option") : _vm._e()]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }