var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalPages > 1
    ? _c(
        "div",
        { staticClass: "input-group input-group-sm" },
        [
          _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button", disabled: _vm.page === 1 },
                on: {
                  click: function ($event) {
                    _vm.page = 1
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-angles-left" })]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button", disabled: _vm.page === 1 },
                on: {
                  click: function ($event) {
                    _vm.page--
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-angle-left" })]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "span",
              { staticClass: "input-group-text bg-light text-primary" },
              [_vm._v(_vm._s(_vm.$t("Page")))]
            ),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.page,
                expression: "page",
                modifiers: { number: true },
              },
            ],
            staticClass: "input form-control",
            domProps: { value: _vm.page },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.page = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c(
              "span",
              { staticClass: "input-group-text bg-light text-primary" },
              [_vm._v(_vm._s(_vm.$t("of")) + " " + _vm._s(_vm.totalPages))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: {
                  type: "button",
                  disabled: _vm.page === _vm.totalPages,
                },
                on: {
                  click: function ($event) {
                    _vm.page++
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-angle-right" })]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: {
                  type: "button",
                  disabled: _vm.page === _vm.totalPages,
                },
                on: {
                  click: function ($event) {
                    _vm.page = _vm.totalPages
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-angles-right" })]
            ),
          ]),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }