var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.extras, function (extra, index) {
      return _c("div", { key: extra.id }, [
        _c("div", { staticClass: "row my-2 my-md-0" }, [
          _c("div", { staticClass: "col-md-10" }, [
            _c(
              "div",
              { staticClass: "form-check" },
              [
                _c("input", {
                  staticClass: "form-check-input",
                  attrs: {
                    id: `checkbox-${extra.id}`,
                    type: "checkbox",
                    disabled: extra.disabled,
                  },
                  domProps: { checked: extra.checked },
                  on: {
                    click: function ($event) {
                      return _vm.checkExtra(extra, $event.target.checked)
                    },
                  },
                }),
                _vm._v(" "),
                !_vm.kadi.utils.isNestedType(extra.type)
                  ? _c(
                      "label",
                      {
                        staticClass: "form-check-label key",
                        attrs: { for: `checkbox-${extra.id}` },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(extra.key || `(${index + 1})`) +
                            "\n          "
                        ),
                      ]
                    )
                  : _c(
                      "collapse-item",
                      {
                        attrs: {
                          id: extra.id,
                          "show-icon-class": "",
                          "hide-icon-class": "",
                          "is-collapsed": true,
                        },
                      },
                      [
                        _c(
                          "strong",
                          {
                            staticClass: "key",
                            class: { "text-muted": extra.disabled },
                          },
                          [_vm._v(_vm._s(extra.key || `(${index + 1})`))]
                        ),
                      ]
                    ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2 d-md-flex justify-content-end" }, [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                _vm._s(
                  _vm.kadi.utils.capitalize(
                    _vm.kadi.utils.prettyTypeName(extra.type)
                  )
                )
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.kadi.utils.isNestedType(extra.type)
          ? _c(
              "div",
              { attrs: { id: extra.id } },
              [
                _c("extras-selector-items", {
                  staticClass: "border-dotted pl-5 ml-2",
                  attrs: { extras: extra.value },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }