var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "markdown",
    domProps: { innerHTML: _vm._s(_vm.result) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }