var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dialog", staticClass: "modal", attrs: { tabindex: "-1" } },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-xl" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header bg-light py-2" }, [
              _c("strong", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
            _vm._v(" "),
            _vm.showFooter
              ? _c(
                  "div",
                  { staticClass: "modal-footer d-block" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: { type: "button", "data-dismiss": "modal" },
      },
      [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }