var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-field", {
    attrs: { field: _vm.field },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("dynamic-selection", {
                    class: [
                      { "has-error": props.hasError },
                      "select2-hidden-accessible",
                    ],
                    attrs: {
                      id: _vm.field.id,
                      endpoint: _vm.endpoint,
                      placeholder: _vm.placeholder,
                      "initial-values": _vm.initialValues,
                      multiple: _vm.multiple,
                      tags: _vm.tags,
                      "max-input-length": _vm.maxInputLength_,
                      "container-classes": _vm.containerClasses,
                      name: _vm.field.name,
                      required: _vm.field.validation.required,
                      disabled: _vm.disabled,
                    },
                    on: { select: _vm.selectItem, unselect: _vm.unselectItem },
                  }),
                  _vm._v(" "),
                  _vm._t("append"),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }