var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-2 ws-nowrap" }, [_vm._v(_vm._s(_vm.min))]),
    _vm._v(" "),
    _c("div", { staticClass: "col-8" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.value,
            expression: "value",
            modifiers: { number: true },
          },
        ],
        staticClass: "w-100",
        attrs: { type: "range", min: _vm.min, max: _vm.max, step: _vm.step },
        domProps: { value: _vm.value },
        on: {
          change: function ($event) {
            return _vm.$emit("change", _vm.value)
          },
          __r: function ($event) {
            _vm.value = _vm._n($event.target.value)
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2 ws-nowrap d-flex justify-content-end" }, [
      _vm._v(_vm._s(_vm.max)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }