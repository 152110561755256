var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      ref: "trigger",
      staticClass: "btn btn-light",
      attrs: { type: "button", title: _vm.msg },
      on: { click: _vm.copy },
    },
    [
      _vm._t("default", function () {
        return [_c("i", { staticClass: "fa-solid fa-copy" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }