var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.extras, function (extra, index) {
      return _c("div", { key: extra.id }, [
        _c(
          "div",
          {
            staticClass:
              "row my-1 my-md-0 align-items-center cursor-pointer extra",
            on: {
              click: function ($event) {
                return _vm.focusExtra(extra, $event)
              },
            },
          },
          [
            _c("div", { staticClass: "col-md-9" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm.kadi.utils.isNestedType(extra.type.value)
                    ? _c(
                        "collapse-item",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            id: extra.id,
                            "show-icon-class":
                              "fa-regular fa-square-plus collapse-toggle",
                            "hide-icon-class":
                              "fa-regular fa-square-minus collapse-toggle",
                          },
                        },
                        [_c("span")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "py-1",
                      class: {
                        "font-weight-bold": _vm.kadi.utils.isNestedType(
                          extra.type.value
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(extra.key.value || `(${index + 1})`) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3 d-md-flex justify-content-end" },
              [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(
                    _vm._s(
                      _vm.kadi.utils.capitalize(
                        _vm.kadi.utils.prettyTypeName(extra.type.value)
                      )
                    )
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.kadi.utils.isNestedType(extra.type.value)
          ? _c(
              "div",
              {
                staticClass: "border-dotted pl-4 ml-1",
                attrs: { id: extra.id },
              },
              [
                _c("extras-editor-tree-view", {
                  attrs: { extras: extra.value.value },
                  on: { "focus-extra": _vm.focusExtra },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }