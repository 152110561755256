<!-- Copyright 2024 Karlsruhe Institute of Technology
   -
   - Licensed under the Apache License, Version 2.0 (the "License");
   - you may not use this file except in compliance with the License.
   - You may obtain a copy of the License at
   -
   -     http://www.apache.org/licenses/LICENSE-2.0
   -
   - Unless required by applicable law or agreed to in writing, software
   - distributed under the License is distributed on an "AS IS" BASIS,
   - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   - See the License for the specific language governing permissions and
   - limitations under the License. -->

<template>
  <span v-if="type" class="badge badge-primary font-weight-normal elevated">
    <span v-if="isTemplate">{{ kadi.utils.capitalize(type) }}</span>
    <span v-else :title="type">{{ kadi.utils.truncate(type, 20) }}</span>
  </span>
</template>

<script>
export default {
  props: {
    type: String,
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
